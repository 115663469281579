import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../store/slices/navigationSlice";
import { setCase, removeCase } from "../../store/slices/caseSlice";
import { useGetQuery } from "../../store/slices/apiSlice";
import styles from "./Menu.module.css";

function Menu() {
  let inactiveCase = useSelector((state) => state.case.label);
  const [caseName, setCaseName] = useState(inactiveCase);

  const activeCaseEP = useSelector((state) => state.endpoints.caseActive);
  const { data: activeCase, refetch } = useGetQuery(activeCaseEP, {
    refetchOnMountOrArgChange: true,
    pollingInterval: 12000,
  });

  useEffect(() => {
    if (activeCase?.name) {
      setCaseName(activeCase.name);
      setCase({
        label: activeCase.name,
        id: activeCase.id,
      });
    } else {
      removeCase();
      setCaseName(inactiveCase);
    }
  }, [activeCase]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isClosed = useSelector((state) => state.navigation.isClosed);

  const handleMenuItemClick = (menuItem, index) => {
    navigate(`/${menuItem}`);
    if (isClosed)
      dispatch(
        setActivePage({
          activePage: menuItem,
          activeMenuIndex: -1,
          isClosed: isClosed,
        })
      );
    else
      dispatch(
        setActivePage({
          activePage: menuItem,
          activeMenuIndex: index,
          isClosed: isClosed,
        })
      );
  };

  const setActive = (menuItem, index) => {
    if (isClosed)
      dispatch(
        setActivePage({
          activePage: menuItem,
          activeMenuIndex: -1,
          isClosed: isClosed,
        })
      );
    else
      dispatch(
        setActivePage({
          activePage: menuItem,
          activeMenuIndex: index,
          isClosed: isClosed,
        })
      );
  };

  return (
    <div className={styles.menu}>
      <Link
        to="/"
        className={styles.menu_logo}
        onClick={() => setActive("incidentMonitoring", 0)}
      >
        Drilling Solutions
      </Link>
      <Button
        rounded
        size="small"
        className="custom-btn"
        onClick={() => handleMenuItemClick("allCases", 1)}
        label={caseName}
      />
    </div>
  );
}

export default Menu;
