import React from "react";
import { Route, Routes, HashRouter } from "react-router-dom";
import IncidentMonitoring from "./pages/Case/IncidentMonitoring/IncidentMonitoring";
import FormationsPressures from "./pages/Case/FormationsPressures";
import BoreholeGeometry from "./pages/Case/BoreholeGeometry";
import DrillingFluid from "./pages/Case/DrillingFluid";
import Inclinometry from "./pages/Case/Inclinometry";
import Plan from "./pages/Case/Plan";
import RigData from "./pages/Case/RigData";
import NewCase from "./pages/CaseManagment/NewCase";
import AllCases from "./pages/CaseManagment/AllCases";
import Menu from "./components/Menu/Menu";
import Burgermenu from "./components/Burgermenu/Burgermenu";
import "./App.css";
import "./styles.css";

function App() {
  return (
    <HashRouter>
      <Menu />
      <div className="control-section">
        <Burgermenu />
        <main>
          <div id="maincontent" className="content">
            <Routes>
              <Route exact path="/" element={<IncidentMonitoring />} />
              <Route exact path="/inclinometry" element={<Inclinometry />} />
              <Route
                exact
                path="/formationsPressures"
                element={<FormationsPressures />}
              />
              <Route
                exact
                path="/boreholeGeometry"
                element={<BoreholeGeometry />}
              />
              <Route exact path="/drillingFluid" element={<DrillingFluid />} />
              <Route exact path="/plan" element={<Plan />} />
              <Route exact path="/rigData" element={<RigData />} />

              <Route exact path="/newCase" element={<NewCase />} />
              <Route exact path="/allCases" element={<AllCases />} />
            </Routes>
          </div>
        </main>
      </div>
    </HashRouter>
  );
}
export default App;
