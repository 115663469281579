import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Tree } from "primereact/tree";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ContextMenu } from "primereact/contextmenu";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setCase } from "../../store/slices/caseSlice";
import { setActivePage } from "../../store/slices/navigationSlice";
import {
  useGetQuery,
  useAddMutation,
  useUpdateMutation,
  useRemoveMutation,
} from "../../store/slices/apiSlice";

const AllCases = () => {
  //-----GET CUSTOMERS-----
  const customersEP = useSelector((state) => state.endpoints.customers);
  const { data: customers } = useGetQuery(customersEP, {
    refetchOnMountOrArgChange: true,
  });

  let customersNodes;
  if (customers) {
    customersNodes = customers.items.map((customer) => {
      return {
        key: `customer-${customer.id}`,
        id: customer.id,
        label: customer.name,
        icon: "pi pi-fw pi-user",
        children: customer.fields,
      };
    });
  }

  //-----GET FIELDS-----
  const fieldsEP = useSelector((state) => state.endpoints.fields);
  const { data: fields } = useGetQuery(fieldsEP, {
    refetchOnMountOrArgChange: true,
  });

  let fieldsNodes;
  if (fields) {
    fieldsNodes = fields.items.map((field) => {
      return {
        key: `field-${field.id}`,
        id: field.id,
        parentId: `customer-${field.customerId}`,
        parent: field.customerId,
        label: field.name,
        icon: "pi pi-fw pi-flag",
        children: field.wells,
      };
    });
  }

  //-----GET WELL-CLUSTERS-----
  const wellClustersEP = useSelector((state) => state.endpoints.wellClusters);
  const { data: wellClusters } = useGetQuery(wellClustersEP, {
    refetchOnMountOrArgChange: true,
  });

  let wellClustersNodes;
  if (wellClusters) {
    wellClustersNodes = wellClusters.items.map((wellCluster) => {
      return {
        key: `wellCluster-${wellCluster.id}`,
        id: wellCluster.id,
        parentId: `field-${wellCluster.fieldId}`,
        parent: wellCluster.fieldId,
        label: wellCluster.name,
        icon: "pi pi-fw pi-cloud",
        children: wellCluster.wells,
      };
    });
  }

  //-----GET WELLS-----
  const wellsEP = useSelector((state) => state.endpoints.wells);
  const { data: wells } = useGetQuery(wellsEP, {
    refetchOnMountOrArgChange: true,
  });

  let wellsNodes;
  if (wells) {
    wellsNodes = wells.items.map((well) => {
      return {
        key: `well-${well.id}`,
        id: well.id,
        id: `${well.id}`,
        parentId: `wellCluster-${well.clusterId}`,
        parent: well.clusterId,
        label: well.name,
        icon: "pi pi-fw pi-filter",
        children: well.sections,
      };
    });
  }

  //-----GET WELL-SECTIONS-----
  const wellSectionsEP = useSelector((state) => state.endpoints.wellSections);
  const { data: wellSections } = useGetQuery(wellSectionsEP, {
    refetchOnMountOrArgChange: true,
  });

  let wellSectionsNodes;
  if (wellSections) {
    wellSectionsNodes = wellSections.items.map((wellSection) => {
      return {
        key: `wellSection-${wellSection.id}`,
        id: wellSection.id,
        parentId: `well-${wellSection.wellId}`,
        parent: wellSection.wellId,
        label: wellSection.name,
        icon: "pi pi-fw pi-pause",
        children: [],
      };
    });
  }

  //-----GET CASES-----
  const casesEP = useSelector((state) => state.endpoints.cases);
  const { data: cases } = useGetQuery(casesEP, {
    refetchOnMountOrArgChange: true,
  });

  let casesNodes;
  if (cases) {
    casesNodes = cases.items.map((item) => {
      return {
        key: `case-${item.id}`,
        id: item.id,
        parentId: `wellSection-${item.wellSectionId}`,
        parent: item.wellSectionId,
        label: item.name,
        icon: "pi pi-folder-open",
      };
    });
  }

  const [treeData, setTreeData] = useState(customersNodes);

  //-----Tree-----
  const mapNodes = () => {
    customersNodes?.map((element) => {
      fieldsNodes?.map((field) => {
        if (field.parentId === element.key) {
          element.children = [...element.children, field];
        }
      });
    });

    fieldsNodes?.map((element) => {
      wellClustersNodes?.map((wellCluster) => {
        if (wellCluster.parentId === element.key) {
          element.children = [...element.children, wellCluster];
        }
      });
    });

    wellClustersNodes?.map((element) => {
      wellsNodes?.map((well) => {
        if (well.parentId === element.key) {
          element.children = [...element.children, well];
        }
      });
    });

    wellsNodes?.map((element) => {
      wellSectionsNodes?.map((wellSection) => {
        if (wellSection.parentId === element.key) {
          element.children = [...element.children, wellSection];
        }
      });
    });

    wellSectionsNodes?.map((element) => {
      casesNodes?.map((caseNode) => {
        if (caseNode.parentId === element.key) {
          element.children = [...element.children, caseNode];
        }
      });
    });

    setTreeData(customersNodes);
    console.log("MAPPED", customersNodes);
  };

  useEffect(() => {
    mapNodes();
  }, [cases]);

  //---Expand-behavior---
  const [selectedKey, setSelectedKey] = useState("");
  const [selectedNode, setSelectedNode] = useState("");
  const [expandedKeys, setExpandedKeys] = useState({ 0: true, "0-0": true });

  const expandAll = () => {
    let _expandedKeys = {};

    for (let node of treeData) {
      expandNode(node, _expandedKeys);
    }

    setExpandedKeys(_expandedKeys);
  };

  const collapseAll = () => {
    setExpandedKeys({});
  };

  const expandNode = (node, _expandedKeys) => {
    if (node?.children && node?.children.length) {
      _expandedKeys[node?.key] = true;

      for (let child of node?.children) {
        expandNode(child, _expandedKeys);
      }
    }
  };

  const handleOnNodeSelection = (e) => {
    setSelectedKey(e.value);
    console.log("SELECTED KEY", e.value);

    if (e.value.includes("case")) {
      setSelectedCase(true);
    } else setSelectedCase(false);
  };

  //-----Tree-Context-Menu-----
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const [addItem] = useAddMutation();
  const [editItem] = useUpdateMutation();
  const [deleteItem] = useRemoveMutation();

  const toast = useRef(null);
  const cm = useRef(null);

  const menu = selectedKey?.includes("case")
    ? [
        {
          label: "Создать",
          icon: "pi pi-plus",
          command: () => {
            dispatch(
              setActivePage({
                activePage: "newCase",
              })
            );
            navigate("/newCase");
          },
        },
        {
          label: "Редактировать",
          icon: "pi pi-pencil",
          command: () => {
            setIsEdit(true);
            setNodeLabel(selectedNode.label);
            setVisibleDialog(true);
            /*
            dispatch(
              setActivePage({
                activePage: "editCase",
              })
            );
            navigate("/newCase");
            */
          },
        },
        {
          label: "Удалить",
          icon: "pi pi-trash",
          command: () => {
            removeNode(selectedNode);
          },
        },
      ]
    : [
        {
          label: "Создать",
          icon: "pi pi-plus",
          command: () => {
            setNodeLabel("");
            setVisibleDialog(true);
            expandNode(selectedNode, {});
          },
        },
        {
          label: "Редактировать",
          icon: "pi pi-pencil",
          command: () => {
            setIsEdit(true);
            setNodeLabel(selectedNode.label);
            setVisibleDialog(true);
          },
        },
        {
          label: "Удалить",
          icon: "pi pi-trash",
          command: () => {
            removeNode(selectedNode);
          },
        },
      ];

  //-----Tree-----
  const getParentKey = () => {
    if (selectedNode.key.includes("customer")) return "customer";
    if (selectedNode.key.includes("field")) return "field";
    if (selectedNode.key.includes("wellCluster")) return "wellCluster";
    if (selectedNode.key.includes("wellSection")) return "wellSection";
    if (selectedNode.key.includes("well")) return "well";
    if (selectedNode.key.includes("case")) return "case";
  };

  const getNodeKey = (parentKey) => {
    switch (parentKey) {
      case "customer":
        return "field";
      case "field":
        return "wellCluster";
      case "wellCluster":
        return "well";
      case "well":
        return "wellSection";
      default:
        return "case";
    }
  };

  const getNode = (parentKey) => {
    switch (parentKey) {
      case "customer":
        return customersNodes.find((element) => element.id === selectedNode.id);
      case "field":
        return fieldsNodes.find((element) => element.id === selectedNode.id);
      case "wellCluster":
        return wellClustersNodes.find(
          (element) => element.id === selectedNode.id
        );
      case "wellSection":
        return wellSectionsNodes.find(
          (element) => element.id === selectedNode.id
        );
      case "well":
        return wellsNodes.find((element) => element.id === selectedNode.id);
      default:
        return casesNodes.find((element) => element.id === selectedNode.id);
    }
  };

  const getNodeEP = (parentKey) => {
    switch (parentKey) {
      case "customer":
        return customersEP;
      case "field":
        return fieldsEP;
      case "wellCluster":
        return wellClustersEP;
      case "wellSection":
        return wellSectionsEP;
      case "well":
        return wellsEP;
      default:
        return casesEP;
    }
  };

  const getParentNodeEP = (parentKey) => {
    switch (parentKey) {
      case "customer":
        return fieldsEP;
      case "field":
        return wellClustersEP;
      case "wellCluster":
        return wellsEP;
      case "well":
        return wellSectionsEP;
      default:
        return casesEP;
    }
  };

  const getNodeIcon = (parentKey) => {
    switch (parentKey) {
      case "customer":
        return "pi pi-fw pi-flag";
      case "field":
        return "pi pi-fw pi-cloud";
      case "wellCluster":
        return "pi pi-fw pi-filter";
      case "well":
        return "pi pi-fw pi-pause";
      default:
        return "pi pi-folder-open";
    }
  };

  //-----ADD-NODE------
  const postNode = (parentKey, nodeEP, newNode) => {
    switch (parentKey) {
      case "customer":
        return addItem({
          endpoint: nodeEP,
          id: "",
          body: {
            name: newNode.label,
            customerId: newNode.parent,
          },
        }).then((response) => {
          if (response.data) {
            toast.current.show({
              severity: "success",
              summary: "Успешно",
              detail: "Данные сохранены.",
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "warn",
              summary: "Что-то пошло не так",
              detail: "Изменения не сохранены. Пожалуйста, попробуйте позже.",
              life: 3000,
            });
          }
        });
      case "field":
        return addItem({
          endpoint: nodeEP,
          id: "",
          body: {
            name: newNode.label,
            fieldId: newNode.parent,
          },
        }).then((response) => {
          if (response.data) {
            toast.current.show({
              severity: "success",
              summary: "Успешно",
              detail: "Данные сохранены.",
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "warn",
              summary: "Что-то пошло не так",
              detail: "Изменения не сохранены. Пожалуйста, попробуйте позже.",
              life: 3000,
            });
          }
        });
      case "wellCluster":
        return addItem({
          endpoint: nodeEP,
          id: "",
          body: {
            name: newNode.label,
            wellClusterId: newNode.parent,
          },
        }).then((response) => {
          if (response.data) {
            toast.current.show({
              severity: "success",
              summary: "Успешно",
              detail: "Данные сохранены.",
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "warn",
              summary: "Что-то пошло не так",
              detail: "Изменения не сохранены. Пожалуйста, попробуйте позже.",
              life: 3000,
            });
          }
        });

      case "well":
        return addItem({
          endpoint: nodeEP,
          id: "",
          body: {
            name: newNode.label,
            wellId: newNode.parent,
          },
        }).then((response) => {
          if (response.data) {
            toast.current.show({
              severity: "success",
              summary: "Успешно",
              detail: "Данные сохранены.",
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "warn",
              summary: "Что-то пошло не так",
              detail: "Изменения не сохранены. Пожалуйста, попробуйте позже.",
              life: 3000,
            });
          }
        });
      default:
        return addItem({
          endpoint: nodeEP,
          id: "",
          body: {
            name: newNode.label,
            wellSectionId: newNode.parent,
          },
        }).then((response) => {
          if (response.data) {
            toast.current.show({
              severity: "success",
              summary: "Успешно",
              detail: "Данные сохранены.",
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "warn",
              summary: "Что-то пошло не так",
              detail: "Изменения не сохранены. Пожалуйста, попробуйте позже.",
              life: 3000,
            });
          }
        });
    }
  };

  const addNode = () => {
    let parentKey = getParentKey();
    let icon = getNodeIcon(parentKey);
    let key = getNodeKey(parentKey);
    let parentNode = getNode(parentKey);

    let newNode = {
      id: "",
      key: `${key}-${selectedNode.id}-${selectedNode.children.length + 1}`,
      parentId: `${parentKey.id}-${selectedNode.id}`,
      parent: selectedNode.id,
      label: nodeLabel,
      icon: icon,
      children: [],
    };

    let nodeEP = getParentNodeEP(parentKey);
    postNode(parentKey, nodeEP, newNode);

    parentNode.children = [...parentNode.children, newNode];
    console.log("new node", newNode);
    mapNodes();
    setNodeLabel("");
  };

  const addCustomer = () => {
    let customer = {
      id: "",
      key: `customer-${customersNodes.length + 1}`,
      label: nodeLabel,
      icon: "pi pi-fw pi-user",
      children: [],
    };
    addItem({
      endpoint: customersEP,
      id: "",
      body: {
        name: customer.label,
      },
    })
      .then((response) => {
        if (response) {
          toast.current.show({
            severity: "success",
            summary: "Успешно",
            detail: "Данные сохранены",
            life: 3000,
          });
        }
      })
      .catch((error) => {
        toast.current.show({
          severity: "warn",
          summary: "Что-то пошло не так",
          detail: "Пожалуйста, попробуйте позже",
          life: 3000,
        });
      });

    customersNodes.push(customer);
    setTreeData(customersNodes);
    setNodeLabel("");
    setIsCustomer(false);
    console.log("New customer", customersNodes);
  };

  //-----EDIT-NODE------
  const putNode = (parentKey, nodeEP) => {
    switch (parentKey) {
      case "customer":
        return editItem({
          endpoint: nodeEP,
          id: `/${selectedNode.id}`,
          body: { name: nodeLabel },
        }).then((response) => {
          if (response.data) {
            toast.current.show({
              severity: "success",
              summary: "Успешно",
              detail: "Данные сохранены.",
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "warn",
              summary: "Что-то пошло не так",
              detail: "Изменения не сохранены. Пожалуйста, попробуйте позже.",
              life: 3000,
            });
          }
        });

      case "field":
        return editItem({
          endpoint: nodeEP,
          id: `/${selectedNode.id}`,
          body: { name: nodeLabel, customerId: selectedNode.parent },
        }).then((response) => {
          if (response.data) {
            toast.current.show({
              severity: "success",
              summary: "Успешно",
              detail: "Данные сохранены.",
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "warn",
              summary: "Что-то пошло не так",
              detail: "Изменения не сохранены. Пожалуйста, попробуйте позже.",
              life: 3000,
            });
          }
        });

      case "wellCluster":
        return editItem({
          endpoint: nodeEP,
          id: `/${selectedNode.id}`,
          body: { name: nodeLabel, fieldId: selectedNode.parent },
        }).then((response) => {
          if (response.data) {
            toast.current.show({
              severity: "success",
              summary: "Успешно",
              detail: "Данные сохранены.",
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "warn",
              summary: "Что-то пошло не так",
              detail: "Изменения не сохранены. Пожалуйста, попробуйте позже.",
              life: 3000,
            });
          }
        });

      case "wellSection":
        return editItem({
          endpoint: nodeEP,
          id: `/${selectedNode.id}`,
          body: { name: nodeLabel, wellId: selectedNode.parent },
        }).then((response) => {
          if (response.data) {
            toast.current.show({
              severity: "success",
              summary: "Успешно",
              detail: "Данные сохранены.",
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "warn",
              summary: "Что-то пошло не так",
              detail: "Изменения не сохранены. Пожалуйста, попробуйте позже.",
              life: 3000,
            });
          }
        });

      case "well":
        return editItem({
          endpoint: nodeEP,
          id: `/${selectedNode.id}`,
          body: { name: nodeLabel, wellClusterId: selectedNode.parent },
        }).then((response) => {
          if (response.data) {
            toast.current.show({
              severity: "success",
              summary: "Успешно",
              detail: "Данные сохранены.",
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "warn",
              summary: "Что-то пошло не так",
              detail: "Изменения не сохранены. Пожалуйста, попробуйте позже.",
              life: 3000,
            });
          }
        });
      default:
        return editItem({
          endpoint: nodeEP,
          id: `/${selectedNode.id}`,
          body: { name: nodeLabel, wellSectionId: selectedNode.parent },
        }).then((response) => {
          if (response.data) {
            toast.current.show({
              severity: "success",
              summary: "Успешно",
              detail: "Данные сохранены.",
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "warn",
              summary: "Что-то пошло не так",
              detail: "Изменения не сохранены. Пожалуйста, попробуйте позже.",
              life: 3000,
            });
          }
        });
    }
  };

  const editNode = () => {
    selectedNode.label = nodeLabel;

    let parentKey = getParentKey();
    putNode(parentKey, getNodeEP(parentKey));
  };

  //-----DELETE-NODE------
  const deleteNode = (nodeEP) => {
    return deleteItem({
      endpoint: nodeEP,
      id: `/${selectedNode.id}`,
    }).then((response) => {
      if (response.data) {
        toast.current.show({
          severity: "error",
          summary: "Удалено",
          detail: "Данные удалены.",
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "warn",
          summary: "Что-то пошло не так",
          detail: "Пожалуйста, попробуйте позже.",
          life: 3000,
        });
      }
    });
  };

  const removeNode = () => {
    let parentKey = getParentKey();

    /*let _nodes = [parentNode.children];
    _nodes = _nodes.filter((n) => n.key !== node.key);
    mapNodes();
    setTreeData(customersNodes);*/

    deleteNode(getNodeEP(parentKey));
  };

  //-----Dialog-----
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [nodeLabel, setNodeLabel] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const handleSaveLabel = () => {
    if (nodeLabel === "") {
      setIsEmpty(true);
      toast.current.show({
        severity: "error",
        summary: "Пожалуйста, заполните необходимую информацию!",
      });
    } else {
      setIsEmpty(false);

      if (isEdit) {
        editNode();
        setIsEdit(false);
      } else isCustomer ? addCustomer() : addNode();
      setIsCustomer(false);
      setVisibleDialog(false);
    }
  };

  const newNodeDialog = (
    <div className="btn-wrapper">
      <Button
        label="Сохранить"
        icon="pi pi-check"
        size="small"
        className="custom-btn"
        onClick={handleSaveLabel}
        autoFocus
      />
    </div>
  );

  const handleAddNewCustomer = () => {
    setNodeLabel("");
    setVisibleDialog(true);
    setIsCustomer(true);
  };

  const [selectedCase, setSelectedCase] = useState(false);

  //-----Case-Selection-----
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeCaseEP = useSelector((state) => state.endpoints.caseActive);
  const isClosed = useSelector((state) => state.navigation.isClosed);

  const selectCase = async () => {
    console.log("selectedNode", selectedNode);
    let res = await addItem({
      endpoint: activeCaseEP,
      id: `/${selectedNode.id}`,
    });

    if (res.error) {
      toast.current.show({
        severity: "error",
        summary: "Ошибка!",
        detail: `${res.error.status}: ${res.error.data}`,
        life: 3000,
      });
      return;
    } else {
      dispatch(
        setCase({
          label: selectedNode.label,
          id: selectedNode.id,
        })
      );
      if (isClosed)
        dispatch(
          setActivePage({
            activePage: "incidentMonitoring",
            activeMenuIndex: -1,
            isClosed: isClosed,
          })
        );
      else
        dispatch(
          setActivePage({
            activePage: "incidentMonitoring",
            activeMenuIndex: 0,
            isClosed: isClosed,
          })
        );
      navigate("/");
    }
  };

  return (
    <div
      className="main-home-container"
      id="customersCatalog"
      style={{ paddingRight: 0 }}
    >
      <h4>Все кейсы</h4>
      <div className="rows" style={{ overflowY: "scroll" }}>
        <div className="tree-row">
          <div className="tree">
            <div className="tree-menu" />
            <div className="tree-body">
              <Toast ref={toast} position="bottom-center" />

              <ContextMenu model={menu} ref={cm} />

              <Tree
                value={treeData}
                filter
                filterMode="lenient"
                filterPlaceholder="Поиск"
                className="w-full md:w-30rem"
                selectionMode="single"
                selectionKeys={selectedKey}
                onSelectionChange={(e) => handleOnNodeSelection(e)}
                onSelect={(e) => setSelectedNode(e.node)}
                expandedKeys={expandedKeys}
                onToggle={(e) => setExpandedKeys(e.value)}
                contextMenuSelectionKey={selectedNodeKey}
                onContextMenuSelectionChange={(e) =>
                  setSelectedNodeKey(e.value)
                }
                onContextMenu={(e) => cm.current.show(e.originalEvent)}
              />
            </div>
          </div>
          <div className="tree-control-row">
            <div className="tree-control-btn">
              <Button
                icon="pi pi-plus"
                size="small"
                outlined
                tooltip="Новый заказчик"
                onClick={handleAddNewCustomer}
              />
              <Button
                icon="pi pi-angle-double-down"
                size="small"
                className="custom-btn"
                tooltip="Развернуть все"
                onClick={expandAll}
              />
              <Button
                icon="pi pi-angle-double-up"
                size="small"
                className="custom-btn"
                tooltip="Свернуть все"
                onClick={collapseAll}
              />
            </div>
            <div
              className="tree-control-btn"
              style={{ marginRight: "-12.6rem" }}
            >
              <Button
                size="small"
                label="Выбрать для работы"
                onClick={selectCase}
                style={{ display: selectedCase ? "block" : "none" }}
              />
            </div>
          </div>
          <Dialog
            header="Введите новое имя"
            visible={visibleDialog}
            footer={newNodeDialog}
            onHide={() => setVisibleDialog(false)}
            style={{ width: "32vw" }}
          >
            <div className="card" style={{ paddingTop: "1rem" }}>
              <InputText
                value={nodeLabel}
                onChange={(e) => setNodeLabel(e.target.value)}
                className="p-inputtext-sm"
                style={{ width: "100%" }}
                invalid={isEmpty}
              />
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default AllCases;
