import React, { useState, useEffect } from "react";
import IndicatorsList from "../../../components/Indicator/IndicatorsList";
import GaugeComponent from "react-gauge-component";
import { SelectButton } from "primereact/selectbutton";
import { indicators as indicatorsList } from "./indicators";
import ActiveCaseError from "../../../components/ActiveCaseError/ActiveCaseError";
import styles from "./IncidentMonitoring.module.css";
import RigDataListener from "../../../store/rig-data-ws/rigDataListener";
import { useSelector } from "react-redux";
import { useGetQuery } from "../../../store/slices/apiSlice";

function IncidentMonitoring() {
  const caseName = useSelector((state) => state.case.label);
  const [activeCase, setActiveCase] = useState(
    true
    // caseName == "Выберете кейс для работы" ? false : true
  );

  // Initial indicators
  const initialIndicators = indicatorsList;

  const [gaugeValue, setGaugeValue] = useState(-1);
  const [indicators, setIndicators] = useState(initialIndicators);

  const incidentEP = useSelector((state) => state.endpoints.incidentMonitor);

  const { data: incidents, refetch } = useGetQuery(incidentEP, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (incidents) {
      const incidentData = incidents.states?.map((incident) => ({
        key: incident.name,
        value: incident.value,
        status: incident.status,
      }));

      const updatedIndicators = initialIndicators.map((indicator) => {
        const updatedIndicator = incidentData.find(
          (item) => item.key === indicator.key
        );
        return updatedIndicator
          ? { ...indicator, ...updatedIndicator }
          : indicator;
      });
      console.log(incidents);
      setIndicators(updatedIndicators);
      setGaugeValue(incidents.incidentProbability);
    }
  }, [incidents]);

  useEffect(() => {
    const handleRigDataChanged = (incidents) => {
      console.log("test");
      const incidentData = incidents.States.map((incident) => ({
        key: incident.Name,
        value: incident.Value,
        status: incident.Status,
      }));

      const updatedIndicators = initialIndicators.map((indicator) => {
        const updatedIndicator = incidentData.find(
          (item) => item.key === indicator.key
        );
        return updatedIndicator
          ? { ...indicator, ...updatedIndicator }
          : indicator;
      });
      console.log("updatedIndicators", updatedIndicators);
      setIndicators(updatedIndicators);
      setGaugeValue(incidents.IncidentProbability);
    };

    RigDataListener.addEventListener("OnRigDataChanged", handleRigDataChanged);
    refetch();

    return () => {
      RigDataListener.removeEventListener(
        "OnRigDataChanged",
        handleRigDataChanged
      );
    };
  }, [refetch]);

  const justifyOptions = [
    { value: "gauge1", gaugeValue: gaugeValue, list: indicators },
  ];

  const [selectedGauge, setSelectedGauge] = useState(null);
  const [isSelected, setIsSelected] = useState(false);

  const justifyTemplate = (option) => {
    return (
      <div className={styles._bar}>
        <div className={styles._gauge_form}>
          <GaugeComponent
            type="semicircle"
            value={option.gaugeValue}
            arc={{
              padding: 0,
              cornerRadius: 0,
              width: 0.25,
              colorArray: [
                "var(--indicator-green)",
                "var(--indicator-yellow)",
                "var(--indicator-red)",
              ],
              subArcs: [{ length: 0.25 }, { length: 0.5 }, { length: 0.25 }],
            }}
            pointer={{ type: "arrow" }}
            labels={{
              valueLabel: {
                style: {
                  fontFamily: "var(--font-family)",
                  textShadow: "none",
                  fontWeight: "500",
                  fill: "#464A4F",
                },
              },
              tickLabels: {
                hideMinMax: "true",
              },
            }}
          />
        </div>
        <p className={styles._gauge_form_p}>Индикатор инцидента</p>
      </div>
    );
  };

  const handleSelectGauge = (e) => {
    setSelectedGauge(e.value);
    switch (e.value) {
      case "gauge1":
        setIsSelected(true);
      default:
        setIsSelected(true);
    }
  };

  useEffect(() => {
    if (!selectedGauge) setIsSelected(false);
  }, [selectedGauge]);

  return activeCase ? (
    <div className={styles.box}>
      <div className={styles._main_container} id="home">
        <h4>Мониторинг инцидента</h4>

        <div className={styles.container}>
          <SelectButton
            value={selectedGauge}
            onChange={handleSelectGauge}
            itemTemplate={justifyTemplate}
            optionLabel="value"
            options={justifyOptions}
          />
        </div>
      </div>
      <IndicatorsList list={[...indicators]} isSelected={isSelected} />
    </div>
  ) : (
    <ActiveCaseError pageName={"Мониторинг инцидента"} />
  );
}

export default IncidentMonitoring;
