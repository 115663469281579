import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ActiveCaseError from "../../components/ActiveCaseError/ActiveCaseError";
import RigDataListener from "../../store/rig-data-ws/rigDataListener";
import { useSelector } from "react-redux";
import { useGetQuery } from "../../store/slices/apiSlice";

function RigData() {
  const caseName = useSelector((state) => state.case.label);
  const [activeCase, setActiveCase] = useState(
    true
    // caseName == "Выберете кейс для работы" ? false : true
  );

  const rigEP = useSelector((state) => state.endpoints.rigData);
  const { data: rigData, refetch: refetchRigData } = useGetQuery(rigEP, {
    refetchOnMountOrArgChange: true,
  });

  const [rigDatatable, setRigDatatable] = useState([rigData]);
  const [selectedRow, setSelectedRow] = useState();

  useEffect(() => {
    setRigDatatable([rigData]);
  }, [rigData]);

  useEffect(() => {
    const handleNewData = (data) => {
      console.log("data", data);

      let item = data.Case.RigData;
      console.log("item", item);
      let newData = [
        {
          angle: item.Angle,
          azimuth: item.Azimuth,
          drillBitLoad: item.DrillBitLoad,
          manifoldPressure: item.ManifoldPressure,
          measureDepth: item.MeasureDepth,
          pumpAStrokes: item.PumpAStrokes,
          pumpBStrokes: item.PumpBStrokes,
          topDriveSystemRevs: item.TopDriveSystemRevs,
          topDriveSystemTorque: item.TopDriveSystemTorque,
          trueVerticalDepth: item.TrueVerticalDepth,
          weightOnHook: item.WeightOnHook,
        },
      ];

      console.log("newData", newData);
      setRigDatatable(newData);
    };

    RigDataListener.addEventListener("OnRigDataChanged", handleNewData);
    refetchRigData();

    return () => {
      RigDataListener.removeEventListener("OnRigDataChanged", handleNewData);
    };
  }, [refetchRigData]);

  return activeCase ? (
    <div
      className="main-home-container"
      id="rigData"
      style={{ overflowY: "scroll", paddingBottom: "5rem" }}
    >
      <h4>Данные с БУ</h4>
      <div className="card">
        <DataTable
          value={rigDatatable}
          size="small"
          selectionMode="single"
          selection={selectedRow}
          onSelectionChange={(e) => {
            setSelectedRow(e.value);
          }}
        >
          <Column field="weightOnHook" header="Вес на крюке (тс)" />
          <Column
            field="manifoldPressure"
            header="Давление в манифольде (МПа)"
          />
          <Column
            field="drillBitLoad"
            header="Нагрузка на долото (тс)"
            hidden
          />
          <Column field="measureDepth" header="Глубина забоя (м)" hidden />
          <Column field="trueVerticalDepth" header="Кровля по вертикали (м)" />
          <Column field="topDriveSystemRevs" header="Обороты СВП (об/мин)" />
          <Column field="topDriveSystemTorque" header="Момент на СВП (кН*м)" />
          <Column field="pumpAStrokes" header="Ходы насоса А (ход/мин)" />
          <Column field="pumpBStrokes" header="Ходы насоса В (ход/мин)" />
          <Column field="angle" header="Угол (°)" />
          <Column field="azimuth" header="Азимут (°)" />
        </DataTable>
      </div>
    </div>
  ) : (
    <ActiveCaseError pageName={"Данные с БУ"} />
  );
}

export default RigData;
