import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activePage: "incidentMonitoring",
  activeMenuIndex: -1,
  isClosed: true,
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setActivePage: (state, action) => {
      state.activePage = action.payload.activePage;
      state.activeMenuIndex = action.payload.activeMenuIndex;
      state.isClosed = action.payload.isClosed;
    },
    removeActivePage: (state) => {
      state.activePage = "incidentMonitoring";
      state.activeMenuIndex = -1;
      state.isClosed = true;
    },
  },
});

export const { setActivePage, removeActivePage } = navigationSlice.actions;

export default navigationSlice.reducer;
