import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { TabView, TabPanel } from "primereact/tabview";
import Case from "../../images/Case.svg";
import AllCases from "../../images/AllCases.svg";
import Administration from "../../images/Administration.svg";
import Catalog from "../../images/Catalog.svg";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setActivePage } from "../../store/slices/navigationSlice";
import "./Burgermenu.css";

function Burgermenu() {
  const [version, setVersion] = useState(process.env.REACT_APP_VERSION);

  let isClosed = useSelector((state) => state.navigation.isClosed);
  const [collapsed, setCollapsed] = useState(isClosed);

  let activeMenuIndex = useSelector(
    (state) => state.navigation.activeMenuIndex
  );
  const [activeIndex, setActiveIndex] = useState(activeMenuIndex);

  let activePage = useSelector((state) => state.navigation.activePage);
  const [activeMenuItem, setActiveMenuItem] = useState(activePage);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setActiveIndex(activeMenuIndex);
  }, [activeMenuIndex]);

  const updateNavigation = (page, index, isClosed) => {
    dispatch(
      setActivePage({
        activePage: page,
        activeMenuIndex: index,
        isClosed: isClosed,
      })
    );
  };

  const handleMenuClick = (itemIndex) => {
    setActiveIndex(itemIndex);
    updateNavigation(activeMenuItem, itemIndex, collapsed);

    if (collapsed) {
      setCollapsed(!collapsed);
      updateNavigation(activeMenuItem, itemIndex, !collapsed);
    }
    if (activeIndex === itemIndex) {
      setCollapsed(!collapsed);
      setActiveIndex(-1);
      updateNavigation(activeMenuItem, -1, !collapsed);
    }
  };

  const handleSetActiveIndex = (index) => {
    setActiveIndex(index);
    updateNavigation(activeMenuItem, index, collapsed);
  };

  const tab1HeaderTemplate = () => {
    return (
      <button
        className="p-tabview-nav-link burgermenu-btn"
        title="Кейс"
        onClick={() => handleMenuClick(0)}
      >
        <img src={Case} />
      </button>
    );
  };

  const tab2HeaderTemplate = () => {
    return (
      <button
        className="p-tabview-nav-link burgermenu-btn"
        title="Управление кейсами"
        onClick={() => handleMenuClick(1)}
      >
        <img src={AllCases} />
      </button>
    );
  };

  const tab3HeaderTemplate = () => {
    return (
      <button
        className="p-tabview-nav-link burgermenu-btn"
        title="Администрирование"
        onClick={() => handleMenuClick(2)}
      >
        <img src={Administration} />
      </button>
    );
  };

  const tab4HeaderTemplate = () => {
    return (
      <button
        className="p-tabview-nav-link burgermenu-btn"
        title="Каталог"
        onClick={() => handleMenuClick(3)}
      >
        <img src={Catalog} />
      </button>
    );
  };

  const handleMenuItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
    updateNavigation(menuItem, activeMenuIndex, collapsed);

    if (menuItem === "incidentMonitoring") navigate("");
    else navigate(`/${menuItem}`);
  };

  useEffect(() => {
    handleMenuItemClick(activePage);
  }, [activePage]);

  return (
    <Sidebar collapsed={collapsed} collapsedWidth="52px" width="300px">
      <Menu>
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => handleSetActiveIndex(e.index)}
        >
          <TabPanel headerTemplate={tab1HeaderTemplate}>
            <MenuItem
              activemenuitem={activeMenuItem}
              menuitemindex="incidentMonitoring"
              className={
                activeMenuItem === "incidentMonitoring"
                  ? "active-menu-item"
                  : "default-menu-item"
              }
              onClick={() => handleMenuItemClick("incidentMonitoring")}
            >
              Мониторинг инцидента
            </MenuItem>
            {/*<MenuItem
              activemenuitem={activeMenuItem}
              menuitemindex="inclinometry"
              className={
                activeMenuItem === "inclinometry"
                  ? "active-menu-item"
                  : "default-menu-item"
              }
              onClick={() => handleMenuItemClick("inclinometry")}
            >
              Инклинометрия
            </MenuItem>*/}
            <MenuItem
              activemenuitem={activeMenuItem}
              menuitemindex="formationsPressures"
              className={
                activeMenuItem === "formationsPressures"
                  ? "active-menu-item"
                  : "default-menu-item"
              }
              onClick={() => handleMenuItemClick("formationsPressures")}
            >
              Пласты и давления
            </MenuItem>
            <MenuItem
              activemenuitem={activeMenuItem}
              menuitemindex="boreholeGeometry"
              className={
                activeMenuItem === "boreholeGeometry"
                  ? "active-menu-item"
                  : "default-menu-item"
              }
              onClick={() => handleMenuItemClick("boreholeGeometry")}
            >
              Геометрия ствола скважины
            </MenuItem>
            <MenuItem
              activemenuitem={activeMenuItem}
              menuitemindex="drillingFluid"
              className={
                activeMenuItem === "drillingFluid"
                  ? "active-menu-item"
                  : "default-menu-item"
              }
              onClick={() => handleMenuItemClick("drillingFluid")}
            >
              Буровой раствор
            </MenuItem>
            <MenuItem
              activemenuitem={activeMenuItem}
              menuitemindex="plan"
              className={
                activeMenuItem === "plan"
                  ? "active-menu-item"
                  : "default-menu-item"
              }
              onClick={() => handleMenuItemClick("plan")}
            >
              План
            </MenuItem>
            <MenuItem
              activemenuitem={activeMenuItem}
              menuitemindex="rigData"
              className={
                activeMenuItem === "rigData"
                  ? "active-menu-item"
                  : "default-menu-item"
              }
              onClick={() => handleMenuItemClick("rigData")}
            >
              Данные с буровой установки
            </MenuItem>
            <label className="version">Версия {version}</label>
          </TabPanel>
          <TabPanel headerTemplate={tab2HeaderTemplate}>
            <MenuItem
              activemenuitem={activeMenuItem}
              menuitemindex="allCases"
              className={
                activeMenuItem === "allCases"
                  ? "active-menu-item"
                  : "default-menu-item"
              }
              onClick={() => handleMenuItemClick("allCases")}
            >
              Все кейсы
            </MenuItem>
            <MenuItem
              activemenuitem={activeMenuItem}
              menuitemindex="newCase"
              className={
                activeMenuItem === "newCase"
                  ? "active-menu-item"
                  : "default-menu-item"
              }
              onClick={() => handleMenuItemClick("newCase")}
            >
              Новый кейс
            </MenuItem>

            <label className="version">Версия {version}</label>
          </TabPanel>
          {/*<TabPanel headerTemplate={tab3HeaderTemplate}>
            <MenuItem>Пользователи</MenuItem>
            <MenuItem>Создать нового пользователя</MenuItem>
            <MenuItem>Настройки системы</MenuItem>
          </TabPanel>
          <TabPanel headerTemplate={tab4HeaderTemplate}>
            <MenuItem>Трубы</MenuItem>
          </TabPanel>*/}
        </TabView>
      </Menu>
    </Sidebar>
  );
}

export default Burgermenu;
