import React, { useState, useEffect } from "react";
import IndicatorMessage from "./IndicatorMessage";
import styles from "./Indicator.module.css";

function IndicatorsList({ list, isSelected }) {
  const sortListByStatus = (data) => {
    let sortedData = data.sort(function (a, b) {
      return b.status - a.status;
    });
    return sortedData;
  };

  const filterDataByStatus = (data, value) => {
    let splitedData = data.filter((item) => {
      if (item.status > value) return item;
    });
    return splitedData;
  };

  const [indicators, setIndicators] = useState(
    filterDataByStatus(sortListByStatus(list), 1)
  );

  useEffect(() => {
    isSelected
      ? setIndicators(sortListByStatus(list))
      : setIndicators(filterDataByStatus(sortListByStatus(list), 1));
  }, [list]);

  return (
    <div className={styles.indicators}>
      {indicators.map((item) => {
        return (
          <IndicatorMessage
            key={item.key}
            name={item.name}
            reason={item.reason}
            recommendation={item.recommendation}
            status={item.status}
          />
        );
      })}
    </div>
  );
}

export default IndicatorsList;
