import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import { Column } from "primereact/column";
import { ContextMenu } from "primereact/contextmenu";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Accordion, AccordionTab } from "primereact/accordion";
import ActiveCaseError from "../../components/ActiveCaseError/ActiveCaseError";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  useGetQuery,
  useAddMutation,
  useUpdateMutation,
  useRemoveMutation,
} from "../../store/slices/apiSlice";

function BoreholeGeometry() {
  const caseName = useSelector((state) => state.case.label);
  const [activeCase, setActiveCase] = useState(
    true
    // caseName == "Выберете кейс для работы" ? false : true
  );

  const dispatch = useDispatch();

  const [OKData, setOKData] = useState([]);
  const [selectedOKData, setSelectedOKData] = useState(null);
  const [tubeGeometry, setTubeGeometry] = useState([]);
  const [selectedTubeGeometry, setSelectedTubeGeometry] = useState(null);

  const wellboreGeometryEP = useSelector(
    (state) => state.endpoints.wellboreGeometry
  );
  const casingsEP = useSelector((state) => state.endpoints.casings);
  const innerStringsEP = useSelector((state) => state.endpoints.innerStrings);

  const [deleteItem] = useRemoveMutation();
  const [addData] = useAddMutation();
  const [updateData] = useUpdateMutation();

  const { data: wellboreGeometryRemote, refetchGeometry } = useGetQuery(
    wellboreGeometryEP,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const startContentOK = (
    <React.Fragment>
      <div className="toolbar">
        <label style={{ fontWeight: "600" }}>Предыдущая ОК</label>
      </div>
    </React.Fragment>
  );

  useEffect(() => {
    updateRemoteData();
  }, [wellboreGeometryRemote]);

  const updateRemoteData = () => {
    if (!wellboreGeometryRemote) return;

    console.log("casings", wellboreGeometryRemote.casings);
    setOKData(wellboreGeometryRemote.casings);

    console.log("innerStrings", wellboreGeometryRemote.innerStrings);
    setTubeGeometry(wellboreGeometryRemote.innerStrings);
  };

  useEffect(() => {
    console.log("new tube geometry", tubeGeometry);
  }, [tubeGeometry]);

  useEffect(() => {
    console.log("new casings", OKData);
  }, [OKData]);

  const onOKRowEditComplete = (e) => {
    let { newData, index } = e;

    if (newData.isNew) createCasing(newData, index);
    else updateCasing(newData, index);
  };

  const createCasing = (data, index) => {
    addData({
      endpoint: casingsEP,
      id: "",
      body: data,
    }).then((response) => {
      if (response?.data) {
        let _OKData = [...OKData];
        _OKData.splice(index, 1);
        _OKData.push(response.data);
        setOKData(_OKData);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Ошибка!",
          detail: `${response.error?.code} ${response.error?.data}`,
          life: 1000,
        });
      }
    });
  };

  const updateCasing = (data, index) => {
    let id = data.id;
    delete data.id;
    updateData({
      endpoint: casingsEP,
      id: `/${id}`,
      body: data,
    }).then((response) => {
      if (response?.data) {
        let _OKData = [...OKData];
        _OKData[index] = response.data;
        setOKData(_OKData);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Ошибка!",
          detail: `${response.error?.code} ${response.error?.data}`,
          life: 1000,
        });
      }
    });
  };

  function handleAddOKRow() {
    let id = 1 + Math.max(...OKData.map((d) => d.id));
    let row = {
      id: id,
      topMeasureDepth: 0,
      bottomMeasureDepth: 0,
      length: 0,
      contactFrictionFactor: 0.3,
      casingOuterDiameter: 0,
      casingInnerDiameter: 0,
      isNew: true,
    };
    const _OKData = [row, ...OKData];
    setOKData(_OKData);
  }

  const deleteOK = async (row) => {
    if (!row.isNew) {
      var res = await deleteItem({
        endpoint: casingsEP,
        id: `/${row?.id}`,
      });
      if (res.error) {
        toast.current.show({
          severity: "error",
          summary: "Ошибка!",
          detail: `${res.error.status}: ${res.error.data}`,
          life: 3000,
        });
        return;
      }
    }

    let _OKData = [...OKData];
    _OKData = _OKData.filter((p) => p.id !== row.id);

    toast.current.show({
      severity: "success",
      summary: "Удалено",
      detail: "Данные удалены.",
      life: 3000,
    });

    setOKData(_OKData);
  };

  const startContentTubeGeometry = (
    <React.Fragment>
      <div className="toolbar">
        <label style={{ fontWeight: "600" }}>Внутреннее оборудование</label>
      </div>
    </React.Fragment>
  );

  const onTubeGeometryRowEditComplete = (e) => {
    let { newData, index } = e;

    if (newData.isNew) createTubeGeometry(newData, index);
    else updateTubeGeometry(newData, index);
  };

  const createTubeGeometry = (data, index) => {
    addData({
      endpoint: innerStringsEP,
      id: "",
      body: data,
    }).then((response) => {
      if (response?.data) {
        let _tubeGeometry = [...tubeGeometry];
        _tubeGeometry.splice(index, 1);
        _tubeGeometry.push(response.data);
        setTubeGeometry(_tubeGeometry);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Ошибка!",
          detail: `${response.error?.code} ${response.error?.data}`,
          life: 1000,
        });
      }
    });
  };

  const updateTubeGeometry = (data, index) => {
    let id = data.id;
    delete data.id;
    updateData({
      endpoint: innerStringsEP,
      id: `/${id}`,
      body: data,
    }).then((response) => {
      if (response?.data) {
        let _tubeGeometry = [...tubeGeometry];
        _tubeGeometry[index] = response.data;
        setTubeGeometry(_tubeGeometry);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Ошибка!",
          detail: `${response.error?.code} ${response.error?.data}`,
          life: 1000,
        });
      }
    });
  };

  function handleAddTubeGeometryRow() {
    let id = 1 + Math.max(...tubeGeometry?.map((d) => d.id));
    let row = {
      id: id,
      innerType: "",
      length: 0,
      casingOuterDiameter: 0,
      casingInnerDiameter: 0,
      isNew: true,
    };
    const _tubeGeometry = [row, ...tubeGeometry];
    setTubeGeometry(_tubeGeometry);
  }

  const deleteTubeGeometry = async (row) => {
    if (!row.isNew) {
      var res = await deleteItem({
        endpoint: innerStringsEP,
        id: `/${row?.id}`,
      });
      if (res.error) {
        toast.current.show({
          severity: "error",
          summary: "Ошибка!",
          detail: `${res.error.status}: ${res.error.data}`,
          life: 3000,
        });
        return;
      }
    }

    let _tubeGeometry = [...tubeGeometry];
    console.log(res);
    _tubeGeometry = _tubeGeometry.filter((p) => p.id !== row.id);

    toast.current.show({
      severity: "success",
      summary: "Удалено",
      detail: "Данные удалены.",
      life: 3000,
    });

    setTubeGeometry(_tubeGeometry);
  };

  //-----Tube-context-menu-----
  const cmTubeGeometry = useRef(null);
  const menuTubeGeometry = [
    {
      label: "Удалить",
      icon: "pi pi-fw pi-times",
      command: () => deleteTubeGeometry(selectedTubeGeometry),
    },
  ];
  //-----OK-context-menu-----
  const cmOK = useRef(null);
  const toast = useRef(null);

  const menuOK = [
    {
      label: "Удалить",
      icon: "pi pi-fw pi-times",
      command: () => deleteOK(selectedOKData),
    },
  ];

  //-----editors-----
  const numEditor = (options) => {
    return (
      <InputNumber
        value={options.value}
        onChange={(e) => options.editorCallback(e.value)}
        useGrouping={false}
        maxFractionDigits={2}
        className="p-inputtext-sm"
        style={{ width: "6rem" }}
      />
    );
  };

  const typeOptions = [
    { key: 1, name: "Accelerator" },
    { key: 2, name: "Adj. Near Bit Reamer" },
    { key: 3, name: "Bit" },
    { key: 4, name: "Casing" },
    { key: 5, name: "Casing Scraper" },
    { key: 6, name: "Casing Shoe" },
    { key: 7, name: "Coiled Tubing" },
    { key: 8, name: "Core Barrel" },
    { key: 9, name: "Cuttings Bed Impeller" },
    { key: 10, name: "Drill Collar" },
    { key: 11, name: "Drill Pipe" },
    { key: 12, name: "Eccentric Stabilizer" },
    { key: 13, name: "Float Collar" },
    { key: 14, name: "Heavy Weight" },
    { key: 15, name: "Hole Opener" },
    { key: 16, name: "Jar" },
    { key: 17, name: "Mud Motor" },
    { key: 18, name: "MWD" },
    { key: 19, name: "Port Collar/ Diverter Sub/ Circulating Sub" },
    { key: 20, name: "Stabilizer" },
    { key: 21, name: "Sub" },
    { key: 22, name: "Underreamer" },
  ];

  const dropDownEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        onChange={(e) => options.editorCallback(e.value)}
        options={typeOptions}
        placeholder="Выберете значение"
        optionLabel="name"
        optionValue="name"
        className="w-full md:w-14rem p-inputtext-sm"
        itemTemplate={(option) => {
          return <span>{option.name}</span>;
        }}
      />
    );
  };

  const typeTemplate = (rowData) => {
    console.log(rowData.innerType);
    return rowData.innerType;
  };

  return activeCase ? (
    <div
      className="main-home-container"
      id="boreholeGeometry"
      style={{ paddingBottom: "6rem", overflowY: "scroll" }}
    >
      <h4>Геометрия ствола скважины</h4>
      <Accordion multiple activeIndex={[0, 1]} style={{ fontSize: ".9rem" }}>
        <AccordionTab header="Наружная геометрия">
          <div className="card">
            <Toast ref={toast} position="bottom-center" />
            <ContextMenu
              model={menuOK}
              ref={cmOK}
              onHide={() => setSelectedOKData(null)}
            />
            <Toolbar start={startContentOK} />
            <DataTable
              value={OKData}
              size="small"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              selectionMode="single"
              selection={selectedOKData}
              onSelectionChange={(e) => {
                setSelectedOKData(e.value);
              }}
              dataKey="id"
              editMode="row"
              onRowEditComplete={onOKRowEditComplete}
              onContextMenu={(e) => cmOK.current.show(e.originalEvent)}
              contextMenuSelection={selectedOKData}
              onContextMenuSelectionChange={(e) => setSelectedOKData(e.value)}
            >
              <Column
                field="topMeasureDepth"
                header="От (м, по стволу)"
                sortable
                editor={(options) => numEditor(options)}
              />
              <Column
                field="bottomMeasureDepth"
                header="До (м, по стволу)"
                editor={(options) => numEditor(options)}
              />
              <Column field="length" header="Протяженность (м)" />
              <Column
                field="contactFrictionFactor"
                hidden
                header="К. трения"
                editor={(options) => numEditor(options)}
              />
              <Column
                field="casingOuterDiameter"
                header="Наружный диаметр (мм)"
                editor={(options) => numEditor(options)}
              />
              <Column
                field="casingInnerDiameter"
                header="Внутренний диаметр (мм)"
                editor={(options) => numEditor(options)}
              />
              <Column
                rowEditor
                header={
                  <Button
                    icon="pi pi-plus"
                    onClick={handleAddOKRow}
                    size="small"
                    outlined
                  />
                }
              />
            </DataTable>
          </div>
        </AccordionTab>
        <AccordionTab header="Геометрия труб">
          <div className="card">
            <Toolbar start={startContentTubeGeometry} />
            <ContextMenu
              model={menuTubeGeometry}
              ref={cmTubeGeometry}
              onHide={() => setSelectedTubeGeometry(null)}
            />
            <DataTable
              value={tubeGeometry}
              size="small"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              selectionMode="single"
              selection={selectedTubeGeometry}
              onSelectionChange={(e) => {
                setSelectedTubeGeometry(e.value);
              }}
              dataKey="id"
              editMode="row"
              onRowEditComplete={onTubeGeometryRowEditComplete}
              onContextMenu={(e) =>
                cmTubeGeometry.current.show(e.originalEvent)
              }
              contextMenuSelection={selectedTubeGeometry}
              onContextMenuSelectionChange={(e) =>
                setSelectedTubeGeometry(e.value)
              }
            >
              <Column
                field="no"
                header="№"
                body={(row, props) => props.rowIndex + 1}
              />
              <Column
                field="innerType"
                header="Тип"
                editor={(options) => dropDownEditor(options)}
                body={typeTemplate}
              />
              <Column
                field="length"
                header="Длина (м)"
                editor={(options) => numEditor(options)}
              />
              <Column
                field="casingOuterDiameter"
                header="Наружный диаметр (мм)"
                editor={(options) => numEditor(options)}
              />
              <Column
                field="casingInnerDiameter"
                header="Внутренний диаметр (мм)"
                editor={(options) => numEditor(options)}
              />
              <Column
                rowEditor
                header={
                  <Button
                    icon="pi pi-plus"
                    onClick={handleAddTubeGeometryRow}
                    size="small"
                    outlined
                  />
                }
              />
            </DataTable>
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  ) : (
    <ActiveCaseError pageName={"Геометрия ствола скважины"} />
  );
}

export default BoreholeGeometry;
