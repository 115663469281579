import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customers: "/customers",
  fields: "/fields",
  wellClusters: "/well-clusters",
  wells: "/wells",
  wellSections: "/well-sections",
  cases: "/case",
  caseActive: "/active-case",
  mud: "/active-case/mud",
  rheology: "/active-case/mud/mud-reological-property",
  drillingMudParameters: "/drilling/mud-parameters",
  drillingDrillingParameters: "/drilling/drilling-parameters",
  incidentMonitor: "/active-case/rig-monitor",
  wellboreGeometry: "/active-case/wellbore-geometry",
  casings: "/active-case/wellbore-geometry/casing",
  innerStrings: "/active-case/wellbore-geometry/inner-string",
  formationsPressures: "/active-case/formation-pressure",
  drillingPlan: "/active-case/drilling-plan",
  torque: "/active-case/drilling-plan/torque-plan",
  cuttingsBed: "/active-case/drilling-plan/cutting-bed-plan",
  rigData: "/active-case/rig-data",
};

const endpointsSlice = createSlice({
  name: "endpoints",
  initialState,
});

export const {} = endpointsSlice.actions;
export default endpointsSlice.reducer;
