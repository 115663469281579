import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toolbar } from "primereact/toolbar";
import { ContextMenu } from "primereact/contextmenu";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import ActiveCaseError from "../../components/ActiveCaseError/ActiveCaseError";
import { useSelector } from "react-redux";

function Inclinometry() {
  const caseName = useSelector((state) => state.case.label);
  const [activeCase, setActiveCase] = useState(
    true
    // caseName == "Выберете кейс для работы" ? false : true
  );
  
  const [selectedInputVal, setSelectedInputVal] = useState({
    name: "Зенитный угол",
  });
  const inputValues = [{ name: "Зенитный угол" }];

  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  const startContent = (
    <React.Fragment>
      <div className="toolbar">
        {/*<Button
          icon="pi pi-upload"
          label="Импорт"
          size="small"
          onClick={() => setVisibleDialog(true)}
  />*/}
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
            marginLeft: "1rem",
          }}
        >
          <label>Ввод:</label>
          <Dropdown
            value={selectedInputVal}
            onChange={(e) => setSelectedInputVal(e.value)}
            options={inputValues}
            optionLabel="name"
            className="w-full md:w-14rem p-inputtext-sm"
          />
        </div>
      </div>
    </React.Fragment>
  );

  const onRowEditComplete = (e) => {
    let _tableData = [...tableData];
    let { newData, index } = e;

    _tableData[index] = newData;
    setTableData(_tableData);
  };

  function handleAddRow() {
    let row = {
      id: tableData.length + 1,
      trunkDepth: 0,
      zenithAngle: 0,
      azimuth: 0,
    };

    const _tableData = [row, ...tableData];
    setTableData(_tableData);
  }

  //-----editor-----
  const numEditor = (options) => {
    return (
      <InputNumber
        value={options.value}
        onChange={(e) => options.editorCallback(e.value)}
        useGrouping={false}
        maxFractionDigits={2}
        className="p-inputtext-sm"
        style={{ width: "6rem" }}
      />
    );
  };

  /*---context-menu---*/
  const cm = useRef(null);

  const editToast = useRef(null);

  const menuModel = [
    {
      label: "Удалить",
      icon: "pi pi-fw pi-times",
      command: () => deleteData(selectedRow),
    },
  ];

  const deleteData = (row) => {
    let _tableData = [...tableData];

    _tableData = _tableData.filter((p) => p.id !== row.id);

    editToast.current.show({
      severity: "error",
      summary: "Удалено",
    });

    setTableData(_tableData);
  };

  /*---Casing-Dialog---*/
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [importData, setImportData] = useState([]);
  const [selectedImportData, setSelectedImportData] = useState(null);

  const importColumns = [
    { field: "no", header: "№" },
    { field: "1", header: "Column 1" },
    { field: "2", header: "Column 2" },
    { field: "3", header: "Column 3" },
  ];

  const dialogFooter = (
    <div className="btn-wrapper">
      <Button label="ОК" icon="pi pi-check" size="small" autoFocus />
      <Button
        label="Отмена"
        size="small"
        onClick={() => setVisibleDialog(false)}
        autoFocus
      />
    </div>
  );

  return activeCase ? (
    <div
      className="main-home-container"
      id="inclinometry"
      style={{ overflowY: "scroll" }}
    >
      <h4>Инклинометрия</h4>

      <div className="card">
        {/*<Toolbar start={startContent} />*/}
        <ContextMenu
          model={menuModel}
          ref={cm}
          onHide={() => setSelectedRow(null)}
        />
        <Toast ref={editToast} position="bottom-center" />
        <DataTable
          value={tableData}
          size="small"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          selectionMode="single"
          selection={selectedRow}
          onSelectionChange={(e) => {
            setSelectedRow(e.value);
          }}
          onContextMenu={(e) => cm.current.show(e.originalEvent)}
          contextMenuSelection={selectedRow}
          onContextMenuSelectionChange={(e) => setSelectedRow(e.value)}
          dataKey="id"
          editMode="row"
          onRowEditComplete={onRowEditComplete}
        >
          <Column
            field="trunkDepth"
            header="Глубина по стволу (м)"
            editor={(options) => numEditor(options)}
          />
          <Column
            field="zenithAngle"
            header="Зенитный угол (&deg;)"
            editor={(options) => numEditor(options)}
          />
          <Column
            field="azimuth"
            header="Азимут (&deg;)"
            editor={(options) => numEditor(options)}
          />
          <Column
            header={
              <Button
                icon="pi pi-plus"
                onClick={handleAddRow}
                size="small"
                outlined
              />
            }
          />
          <Column rowEditor />
        </DataTable>
      </div>

      <Dialog
        header="Импорт"
        visible={visibleDialog}
        footer={dialogFooter}
        onHide={() => setVisibleDialog(false)}
        style={{ width: "50vw" }}
      >
        <div className="card" style={{ paddingBottom: "1rem" }}>
          <FileUpload
            name="Выбрать файл"
            size="small"
            url={"/api/upload"}
            single
            accept="*"
            emptyTemplate={
              <p className="m-0">Выберете и перетащите файл для загрузки.</p>
            }
            chooseOptions={{
              label: "Выбрать",
              icon: "pi pi-fw pi-plus",
              size: "small",
            }}
          />
        </div>

        <div className="card">
          <DataTable
            value={importData}
            size="small"
            selectionMode="single"
            selection={selectedImportData}
            onSelectionChange={(e) => setSelectedImportData(e.value)}
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 20, 50]}
          >
            {importColumns.map((col) => (
              <Column key={col.field} field={col.field} header={col.header} />
            ))}
          </DataTable>
        </div>
      </Dialog>
    </div>
  ) : (
    <ActiveCaseError pageName={"Инклинометрия"} />
  );
}

export default Inclinometry;
