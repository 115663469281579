import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { FileUpload } from "primereact/fileupload";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ContextMenu } from "primereact/contextmenu";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Chart } from "primereact/chart";
import ActiveCaseError from "../../components/ActiveCaseError/ActiveCaseError";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  useGetQuery,
  useAddMutation,
  useUpdateMutation,
  useRemoveMutation,
  buildUrl,
} from "../../store/slices/apiSlice";

function Plan() {
  const caseName = useSelector((state) => state.case.label);
  const [activeCase, setActiveCase] = useState(
    true
    // caseName == "Выберете кейс для работы" ? false : true
  );

  const dispatch = useDispatch();

  const [torque, setTorque] = useState([]);
  const [selectedTorque, setSelectedTorque] = useState();

  const [cuttingsBed, setCuttingsBed] = useState([]);
  const [selectedCuttingsBed, setSelectedCuttingsBed] = useState();

  //-----api-----
  const [deleteItem] = useRemoveMutation();
  const [addData] = useAddMutation();
  const [updateData] = useUpdateMutation();

  const torqueEP = useSelector((state) => state.endpoints.torque);
  const cuttingsBedEP = useSelector((state) => state.endpoints.cuttingsBed);

  let { data: torquesRemote, refetch: refetchTorques } = useGetQuery(torqueEP, {
    refetchOnMountOrArgChange: true,
  });

  const { data: bedsRemote, refetch: refetchBeds } = useGetQuery(
    cuttingsBedEP,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    console.log("updateTorques");
    setTorque(torquesRemote);
  }, [torquesRemote]);

  useEffect(() => {
    setCuttingsBed(bedsRemote);
  }, [bedsRemote]);

  const createTorque = (data, index) => {
    addData({
      endpoint: torqueEP,
      id: "",
      body: data,
    }).then((response) => {
      if (response?.data) {
        let _data = [...torque];
        _data.splice(index, 1);
        _data.push(response.data);
        setTorque(_data);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Ошибка!",
          detail: `${response.error?.code} ${response.error?.data}`,
          life: 1000,
        });
      }
    });
  };

  const createCuttingsBed = (data, index) => {
    addData({
      endpoint: cuttingsBedEP,
      id: "",
      body: data,
    }).then((response) => {
      if (response?.data) {
        let _data = [...cuttingsBed];
        _data.splice(index, 1);
        _data.push(response.data);
        setCuttingsBed(_data);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Ошибка!",
          detail: `${response.error?.code} ${response.error?.data}`,
          life: 1000,
        });
      }
    });
  };

  const updateTorque = (data, index) => {
    let id = data.id;
    delete data.id;
    updateData({
      endpoint: torqueEP,
      id: `/${id}`,
      body: data,
    }).then((response) => {
      if (response?.data) {
        let _data = [...torque];
        _data[index] = response.data;
        setTorque(_data);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Ошибка!",
          detail: `${response.error?.code} ${response.error?.data}`,
          life: 1000,
        });
      }
    });
  };

  const updateCuttingsBed = (data, index) => {
    let id = data.id;
    delete data.id;
    updateData({
      endpoint: cuttingsBedEP,
      id: `/${id}`,
      body: data,
    }).then((response) => {
      if (response?.data) {
        let _data = [...cuttingsBed];
        _data[index] = response.data;
        setCuttingsBed(_data);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Ошибка!",
          detail: `${response.error?.code} ${response.error?.data}`,
          life: 1000,
        });
      }
    });
  };

  const deleteTorque = async (row) => {
    var res = await deleteItem({
      endpoint: torqueEP,
      id: "",
    });
    if (res.error) {
      toast.current.show({
        severity: "error",
        summary: "Ошибка!",
        detail: `${res.error.status}: ${res.error.data}`,
        life: 3000,
      });
      return;
    } else {
      toast.current.show({
        severity: "success",
        summary: "Удалено",
        detail: "Данные удалены.",
        life: 3000,
      });

      refetchTorques();
    }

    let _torque = [...torque];
    _torque = _torque.filter((p) => p.id !== row.id);
    setTorque(_torque);
  };

  const deleteCuttingsBed = async (row) => {
    var res = await deleteItem({
      endpoint: cuttingsBedEP,
      id: "",
    });
    if (res.error) {
      toast.current.show({
        severity: "error",
        summary: "Ошибка!",
        detail: `${res.error.status}: ${res.error.data}`,
        life: 3000,
      });
      return;
    } else {
      toast.current.show({
        severity: "success",
        summary: "Удалено",
        detail: "Данные удалены.",
        life: 3000,
      });

      refetchBeds();
    }

    let _cuttingsBed = [...cuttingsBed];
    _cuttingsBed = _cuttingsBed.filter((p) => p.id !== row.id);
    setCuttingsBed(_cuttingsBed);
  };

  //-----toast-----
  const toast = useRef(null);

  //-----editors-----
  const numEditor = (options) => {
    return (
      <InputNumber
        value={options.value}
        onChange={(e) => options.editorCallback(e.value)}
        useGrouping={false}
        maxFractionDigits={2}
        className="p-inputtext-sm"
        style={{ width: "6rem" }}
      />
    );
  };

  //-----chart-----
  const [torqueChart, setTorqueChart] = useState({});
  const [torqueChartOptions, setTorqueChartOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

    let _data = [];
    if (torque) _data = [...torque];
    let sortedData = _data.sort(function (a, b) {
      return b.measuredDepth - a.measuredDepth;
    });

    const data = {
      labels: sortedData?.map((x) => x.measuredDepth),
      datasets: [
        {
          label: "Rotating On Bottom (Н-м)",
          data: sortedData?.map((x) => x.rotatingOnBottom),
          fill: false,
          borderColor: documentStyle.getPropertyValue("--blue-500"),
        },
        {
          label: "Rotating Off Bottom (Н-м)",
          data: sortedData?.map((x) => x.rotatingOffBottom),
          fill: false,
          borderColor: documentStyle.getPropertyValue("--green-500"),
        },
        {
          label: "Ограничение момента (Н-м)",
          data: sortedData?.map((x) => x.torqueLimit),
          fill: false,
          borderColor: documentStyle.getPropertyValue("--orange-500"),
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      indexAxis: "y",
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
          title: {
            display: true,
            text: "Крутящий момент (Н-м)",
          },
        },
        y: {
          reverse: true,
          ticks: {
            reverse: true,
            color: textColorSecondary,
            stepSize: 150,
          },

          grid: {
            color: surfaceBorder,
          },
          title: {
            display: true,
            text: "Кровля по стволу (м)",
          },
        },
      },
    };

    setTorqueChart(data);
    setTorqueChartOptions(options);
  }, [torque]);

  const [cuttingsBedChart, setCuttingsBedChart] = useState({});
  const [cuttingsBedChartOptions, setCuttingsBedChartOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

    let _data = [];
    if (cuttingsBed) _data = [...cuttingsBed];
    let sortedData = _data.sort(function (a, b) {
      return b.distanceAlongString - a.distanceAlongString;
    });

    const data = {
      labels: sortedData?.map((x) => x.distanceAlongString),
      datasets: [
        {
          label: "Толщина шламовой подушки (мм)",
          data: sortedData?.map((x) => x.height),
          fill: false,
          borderColor: documentStyle.getPropertyValue("--green-500"),
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      indexAxis: "y",
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
          title: {
            display: true,
            text: "Толщина шламовой подушки (мм)",
          },
        },
        y: {
          reverse: true,
          ticks: {
            reverse: true,
            color: textColorSecondary,
            stepSize: 150,
          },
          grid: {
            color: surfaceBorder,
          },
          title: {
            display: true,
            text: "Кровля по стволу (м)",
          },
        },
      },
    };

    setCuttingsBedChart(data);
    setCuttingsBedChartOptions(options);
  }, [cuttingsBed]);

  return activeCase ? (
    <div
      className="main-home-container"
      id="plan"
      style={{ overflowY: "scroll", paddingBottom: "5rem" }}
    >
      <h4>План</h4>

      <Accordion multiple activeIndex={[0, 1]} style={{ fontSize: ".9rem" }}>
        <AccordionTab header="Крутящий момент">
          <div
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <div className="card">
              <FileUpload
                name="file"
                url={buildUrl(torqueEP)}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                auto
                onUpload={refetchTorques}
                chooseOptions={{
                  label: "Импортировать",
                  icon: "pi pi-upload",
                  style: {
                    padding: "0.65625rem 1.09375rem",
                    fontSize: "0.875rem",
                    background: "var(--primary-color)",
                    border: "1px solid var(--primary-color)",
                  },
                }}
                uploadOptions={{
                  style: {
                    display: "none",
                  },
                }}
                cancelOptions={{
                  style: {
                    display: "none",
                  },
                }}
              />
            </div>
            <div style={{ display: "flex", gap: "2rem" }}>
              <div className="card">
                <Toast ref={toast} position="bottom-center" />
                <DataTable
                  value={torque}
                  scrollable
                  scrollHeight="450px "
                  size="small"
                  selectionMode="single"
                  selection={selectedTorque}
                  onSelectionChange={(e) => {
                    setSelectedTorque(e.value);
                  }}
                  dataKey="measuredDepth"
                >
                  <Column
                    field="measuredDepth"
                    header="Кровля по стволу (м)"
                    sortable
                    editor={(options) => numEditor(options)}
                  />
                  <Column
                    field="trueVerticalDepth"
                    header="Кровля по вертикали (м)"
                    editor={(options) => numEditor(options)}
                  />
                  <Column
                    field="trippingIn"
                    header="Tripping In (Н-м)"
                    editor={(options) => numEditor(options)}
                    hidden
                  />
                  <Column
                    field="trippingOut"
                    header="Tripping Out (Н-м)"
                    editor={(options) => numEditor(options)}
                    hidden
                  />
                  <Column
                    field="rotatingOnBottom"
                    header="Вращение на забое (Н-м)"
                    editor={(options) => numEditor(options)}
                  />
                  <Column
                    field="rotatingOffBottom"
                    header="Вращение над забоем (Н-м)"
                    editor={(options) => numEditor(options)}
                  />
                  <Column
                    field="torqueLimit"
                    header="Ограничение момента (Н-м)"
                    editor={(options) => numEditor(options)}
                  />
                  <Column
                    header={
                      <Button
                        icon="pi pi-trash"
                        onClick={deleteTorque}
                        size="small"
                        tooltip="Очистить таблицу"
                        outlined
                      />
                    }
                  />
                </DataTable>
              </div>
              <div className="card" style={{ width: "45%" }}>
                <Chart
                  type="line"
                  data={torqueChart}
                  options={torqueChartOptions}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab header="Шламовая подушка">
          <div
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <div className="card">
              <FileUpload
                name="file"
                url={buildUrl(cuttingsBedEP)}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                auto
                onUpload={refetchBeds}
                chooseOptions={{
                  label: "Импортировать",
                  icon: "pi pi-upload",
                  style: {
                    padding: "0.65625rem 1.09375rem",
                    fontSize: "0.875rem",
                    background: "var(--primary-color)",
                    border: "1px solid var(--primary-color)",
                  },
                }}
                uploadOptions={{
                  style: {
                    display: "none",
                  },
                }}
                cancelOptions={{
                  style: {
                    display: "none",
                  },
                }}
              />
            </div>
            <div style={{ display: "flex", gap: "2rem" }}>
              <div className="card">
                <DataTable
                  value={cuttingsBed}
                  scrollable
                  scrollHeight="450px "
                  size="small"
                  selectionMode="single"
                  selection={selectedCuttingsBed}
                  onSelectionChange={(e) => {
                    setSelectedCuttingsBed(e.value);
                  }}
                  dataKey="distanceAlongString"
                >
                  <Column
                    field="distanceAlongString"
                    header="Кровля по стволу (м)"
                    sortable
                    editor={(options) => numEditor(options)}
                  />
                  <Column
                    field="trueVerticalDepth"
                    header="Кровля по вертикали (м)"
                    editor={(options) => numEditor(options)}
                  />
                  <Column
                    field="height"
                    header="Толщина шламовой подушки (мм)"
                    editor={(options) => numEditor(options)}
                  />
                  <Column
                    header={
                      <Button
                        icon="pi pi-trash"
                        onClick={deleteCuttingsBed}
                        size="small"
                        tooltip="Очистить таблицу"
                        outlined
                      />
                    }
                  />
                </DataTable>
              </div>
              <div className="card" style={{ width: "45%" }}>
                <Chart
                  type="line"
                  data={cuttingsBedChart}
                  options={cuttingsBedChartOptions}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  ) : (
    <ActiveCaseError pageName={"План"} />
  );
}

export default Plan;
