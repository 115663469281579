import * as signalR from "@microsoft/signalr";
import { webSocket } from "../slices/apiSlice";

class RigDataListener {
  static #instance;

  constructor(url) {
    if (typeof RigDataListener.#instance === "object")
      return RigDataListener.#instance;
    else RigDataListener.#instance = this;

    this.url = url;
    this.connection = null;
    this.eventListeners = {};

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(this.url)
      .build();

    this.connection.on("RigDataChanged", (data) => {
      this.triggerEvent("OnRigDataChanged", data);
    });

    this.connection.on("MessageRecieved", (data) => {
      this.triggerEvent("MessageRecieved", data);
    });
    
    this.connection.start();
    console.log(this.connection);

    return this;
  }

  triggerEvent(eventName, data) {
    if (this.eventListeners[eventName]) {
      this.eventListeners[eventName].forEach((callback) =>
        callback(JSON.parse(data))
      );
    }
  }

  addEventListener(eventName, callback) {
    if (!this.eventListeners[eventName]) {
      this.eventListeners[eventName] = [];
    }
    // if (this.eventListeners[eventName]?.includes(x => x == callback))
    //   return;
    this.eventListeners[eventName].push(callback);
  }

  removeEventListener(eventName, callback) {
    if (this.eventListeners[eventName]) {
      this.eventListeners[eventName] = this.eventListeners[eventName].filter(
        (cb) => cb !== callback
      );
    }
  }
}

console.log(webSocket + "/rig-data");
let instance = Object.freeze(
  new RigDataListener(`${webSocket}/rig-data`)
);

export default instance;
