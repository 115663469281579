import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ContextMenu } from "primereact/contextmenu";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";
import { Button } from "primereact/button";
import { Chart } from "primereact/chart";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import ActiveCaseError from "../../components/ActiveCaseError/ActiveCaseError";
import { useSelector } from "react-redux";
import {
  useGetQuery,
  useAddMutation,
  useUpdateMutation,
  useRemoveMutation,
} from "../../store/slices/apiSlice";

function FormationsPressures() {
  const caseName = useSelector((state) => state.case.label);
  const [activeCase] = useState(
    true
    // caseName == "Выберете кейс для работы" ? false : true
  );

  /*---dialog---*/
  const [visible, setVisible] = useState(false);

  const dialogHeaderElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">
        Импорт данных по пластам
      </span>
    </div>
  );

  const dialogFooterContent = (
    <div className="btn-wrapper">
      <Button
        label="Ок"
        size="small"
        onClick={() => setVisible(false)}
        autoFocus
      />
    </div>
  );

  /*---table---*/
  const formationsEP = useSelector(
    (state) => state.endpoints.formationsPressures
  );
  const { data: formationRemote, refetchFormations } = useGetQuery(
    formationsEP,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [deleteItem] = useRemoveMutation();
  const [addData] = useAddMutation();
  const [updateData] = useUpdateMutation();

  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  const [pressureReserve, setPressureReserve] = useState(0);
  const [gradientReserve, setGradientReserve] = useState(0);

  useEffect(() => updateRemoteData(), [formationRemote]);

  useEffect(() => setReserve(), [pressureReserve]);
  useEffect(() => setReserve(), [gradientReserve]);

  const setReserve = () => {
    if (pressureReserve && gradientReserve) {
      updateData({
        endpoint: formationsEP,
        id: `/reserve?porePressureReserve=${pressureReserve}&fracPressureReserve=${gradientReserve}`,
      }).then((response) => {
        if (response.error) {
          toast.current.show({
            severity: "error",
            summary: "Ошибка!",
            detail: `${response.error.status}: ${response.error.data}`,
            life: 3000,
          });
          setPressureReserve(0);
          setGradientReserve(0);
          return;
        }
      });
    }
  };

  const updateRemoteData = () => {
    setPressureReserve(formationRemote?.porePressureReserve);
    setGradientReserve(formationRemote?.fracturePressureReserve);
    setTableData(formationRemote?.values);
  };

  const onRowEditComplete = (e) => {
    let { newData, index } = e;

    if (newData.isNew) createFormation(newData, index);
    else updateFormation(newData, index);
  };

  function handleAddRow() {
    let id = 1 + Math.max(...tableData.map((d) => d.id));
    let row = {
      id: id,
      measuredDepth: 0,
      trueVerticalDepth: 0,
      porePressure: 0,
      reservoirGradient: 0,
      reservoirDensity: 0,
      fracturePressure: 0,
      fractureGradient: 0,
      fractureDensity: 0,
      mechanicalSpeed: 0.0,
      isPermeable: false,
      cavernosity: 0,
      isNew: true,
    };

    const _tableData = [row, ...tableData];
    setTableData(_tableData);
  }

  const deleteRow = async (row) => {
    if (!row.isNew) {
      var res = await deleteItem({
        endpoint: formationsEP,
        id: `/${row?.id}`,
      });
      if (res.error) {
        toast.current.show({
          severity: "error",
          summary: "Ошибка!",
          detail: `${res.error.status}: ${res.error.data}`,
          life: 3000,
        });
        return;
      }
    }

    let _data = [...tableData];
    _data = _data.filter((p) => p.id !== row.id);

    toast.current.show({
      severity: "success",
      summary: "Удалено",
      detail: "Данные удалены",
      life: 3000,
    });

    setTableData(_data);
  };

  const getPoreKey = () => {
    if (selectedPorePressure.key === 1) return "PORE_PRESSURE";
    if (selectedPorePressure.key === 2) return "RESERVOIR_GRADIENT";
    if (selectedPorePressure.key === 3) return "RESERVOIR_DENSITY";
    return "";
  };

  const getFracKey = () => {
    if (selectedGrp.key === 1) return "FRACTURE_PRESSURE";
    if (selectedGrp.key === 2) return "FRACTURE_GRADIENT";
    if (selectedGrp.key === 3) return "FRACTURE_DENSITY";
    return "";
  };

  const createFormation = (data, index) => {
    addData({
      endpoint: formationsEP,
      id: `?poreKey=${getPoreKey()}&fracKey=${getFracKey()}`,
      body: data,
    }).then((response) => {
      if (response?.data) {
        let _data = [...tableData];
        _data.splice(index, 1);
        _data.push(response.data);
        setTableData(_data);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Ошибка!",
          detail: `${response.error?.code} ${response.error?.data}`,
          life: 1000,
        });
      }
    });
  };

  const updateFormation = (data, index) => {
    let id = data.id;
    updateData({
      endpoint: formationsEP,
      id: `/${id}?poreKey=${getPoreKey()}&fracKey=${getFracKey()}`,
      body: data,
    }).then((response) => {
      if (response?.data) {
        let _data = [...tableData];
        _data[index] = response.data;
        setTableData(_data);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Ошибка!",
          detail: `${response.error?.code} ${response.error?.data}`,
          life: 1000,
        });
      }
    });
  };

  //-----context-menu-----
  const cm = useRef(null);
  const toast = useRef(null);

  const menuModel = [
    {
      label: "Удалить",
      icon: "pi pi-fw pi-times",
      command: () => deleteRow(selectedRow),
    },
  ];

  /*-----editor----*/
  const numEditor = (options) => {
    return (
      <InputNumber
        value={options.value}
        onChange={(e) => options.editorCallback(e.value)}
        useGrouping={false}
        maxFractionDigits={3}
        className="p-inputtext-sm"
        style={{ width: "6rem" }}
      />
    );
  };

  const toggleEditor = (options) => {
    return (
      <ToggleButton
        onLabel="Да"
        offLabel="Нет"
        className="p-inputtext-sm"
        size="small"
        checked={options.value}
        onChange={(e) => options.editorCallback(e.value)}
      />
    );
  };

  /*---dropdown---*/
  const [selectedPorePressure, setSelectedPorePressure] = useState({
    key: 1,
    name: "Давление",
  });
  const porePressures = [
    { key: 1, name: "Давление" },
    { key: 2, name: "Градиент давления" },
    { key: 3, name: "Плотность" },
  ];

  const [selectedGrp, setSelectedGrp] = useState({
    key: 1,
    name: "Давление",
  });
  const gradients = [
    { key: 1, name: "Давление" },
    { key: 2, name: "Градиент давления" },
    { key: 3, name: "Плотность" },
  ];

  /*---chart---*/
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

    let data = [];
    if (tableData) data = [...tableData];
    let sortedData = data.sort(function (a, b) {
      return b.trueVerticalDepth - a.trueVerticalDepth;
    });
    console.log("SORTED DATA", sortedData);

    const _chartData = {
      labels: sortedData.map((item) => item.trueVerticalDepth),
      type: "line",
      datasets: [
        {
          label: "ЭЦП порового давления (кг/м³)",
          fill: false,
          borderColor: documentStyle.getPropertyValue("--green-500"),
          yAxisID: "y",
          data: sortedData.map((item) => item.reservoirDensity),
        },
        {
          label: "ЭЦП ГРП (кг/м³)",
          fill: false,
          borderColor: documentStyle.getPropertyValue("--orange-500"),
          yAxisID: "y",
          data: sortedData.map((item) => item.fractureDensity),
        },
        {
          label: "Запас по пластовому давлению (кг/м³)",
          fill: false,
          borderDash: [5, 5],
          borderColor: documentStyle.getPropertyValue("--green-500"),
          yAxisID: "y",
          data: sortedData.map(
            (item) => item.reservoirDensity + pressureReserve
          ),
        },
        {
          label: "Запас по градиенту (кг/м³)",
          fill: false,
          borderDash: [5, 5],
          borderColor: documentStyle.getPropertyValue("--orange-500"),
          yAxisID: "y",
          data: sortedData.map(
            (item) => item.fractureDensity + gradientReserve
          ),
        },
      ],
    };

    const options = {
      stacked: false,
      maintainAspectRatio: false,
      aspectRatio: 0,
      indexAxis: "y",
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
          title: {
            display: true,
            text: "Плотность (кг/м³)",
          },
        },
        y: {
          type: "linear",
          display: true,
          position: "left",
          reverse: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
          title: {
            display: true,
            text: "Глубина по вертикали (м)",
          },
        },
      },
    };
    console.log("tableData", tableData);

    setChartData(_chartData);
    setChartOptions(options);
  }, [tableData, gradientReserve, pressureReserve]);

  /*-----observer-----*/
  const element = useRef(null);
  const [width, setWidth] = useState(0);

  const observer = useMemo(
    () =>
      new ResizeObserver((entries) => {
        setWidth(entries[0].target.getBoundingClientRect().width);
      }),
    []
  );

  const sizeRef = useCallback(
    (node) => {
      if (element !== null) {
        if (node !== null) {
          element.current = node;
          observer.observe(node);
        }
      } else {
        observer.unobserve(element.current);
        element.current = null;
      }
    },
    [observer]
  );

  /*-----templates-----*/
  const fractureDensity = (
    <label>
      ЭЦП ГРП (г/см<sup>3</sup>)
    </label>
  );

  const reservoirDensity = (
    <label>
      ЭЦП порового давления (г/см<sup>3</sup>)
    </label>
  );

  const measuredDepthTemplate = (row) => {
    return row.measuredDepth?.toFixed(3);
  };

  const trueVerticalDepthTemplate = (row) => {
    return row.trueVerticalDepth?.toFixed(3);
  };

  const porePressureTemplate = (row) => {
    return row.porePressure?.toFixed(3);
  };

  const reservoirGradientTemplate = (row) => {
    return row.reservoirGradient?.toFixed(3);
  };

  const reservoirDensityTemplate = (row) => {
    return row.reservoirDensity?.toFixed(3);
  };

  const fracturePressureTemplate = (row) => {
    return row.fracturePressure?.toFixed(3);
  };

  const fractureGradientTemplate = (row) => {
    return row.fractureGradient?.toFixed(3);
  };

  const fractureDensityTemplate = (row) => {
    return row.fractureDensity?.toFixed(3);
  };

  const mechanicalSpeedTemplate = (row) => {
    return row.mechanicalSpeed?.toFixed(3);
  };

  const isPermeableTemplate = (row) => {
    return row.isPermeable ? "Да" : "Нет";
  };

  const cavernosityTemplate = (row) => {
    return row.cavernosity?.toFixed(3);
  };

  return activeCase ? (
    <Splitter style={{ height: "100%" }}>
      <SplitterPanel
        className="flex align-items-center justify-content-center"
        size={65}
      >
        <div
          className="main-home-container"
          id="formationsPressures"
          style={{ overflowY: "scroll" }}
        >
          <h4>Пласты и давления</h4>
          <div className="data-wrapper">
            <div
              className="table-wrapper"
              ref={sizeRef}
              style={{
                display: "flex",
                flexDirection: width < 1200 ? "column" : "row",
                direction: width < 1200 ? "ltr" : "rtl",
                paddingBottom: "4rem",
              }}
            >
              <div className="table-tools-wrapper">
                <div className="table-tools-input-wrapper">
                  <p>Параметры ввода</p>
                </div>
                <div
                  className="table-tools-form"
                  style={{
                    flexDirection: width < 1200 ? "row" : "column",
                    width: width < 1200 ? "100%" : "290px",
                    minWidth: width < 1200 ? "810px" : "290px",
                  }}
                >
                  <div className="table-tools-col">
                    <div className="table-tools-input-wrapper">
                      <label>Поровое давление</label>
                      <Dropdown
                        value={selectedPorePressure}
                        onChange={(e) => setSelectedPorePressure(e.value)}
                        options={porePressures}
                        optionLabel="name"
                        placeholder="Выберете значение"
                        className="w-full md:w-14rem p-inputtext-sm"
                      />
                    </div>
                    <div className="table-tools-input-wrapper">
                      <label>
                        Запас по пластовому давлению, кг/м<sup>3</sup>
                      </label>
                      <InputNumber
                        className="p-inputtext-sm"
                        inputId="pressureReserve"
                        useGrouping={false}
                        maxFractionDigits={6}
                        value={pressureReserve}
                        onValueChange={(e) => setPressureReserve(e.value)}
                      />
                    </div>
                  </div>
                  <div className="table-tools-col">
                    <div className="table-tools-input-wrapper">
                      <label>Градиент ГРП</label>
                      <Dropdown
                        value={selectedGrp}
                        onChange={(e) => setSelectedGrp(e.value)}
                        options={gradients}
                        optionLabel="name"
                        placeholder="Выберете значение"
                        className="w-full md:w-14rem p-inputtext-sm"
                      />
                    </div>
                    <div className="table-tools-input-wrapper">
                      <label>
                        Запас по градиенту ГРП, кг/м<sup>3</sup>
                      </label>
                      <InputNumber
                        className="p-inputtext-sm"
                        inputId="gradient"
                        useGrouping={false}
                        maxFractionDigits={6}
                        value={gradientReserve}
                        onValueChange={(e) => setGradientReserve(e.value)}
                      />
                    </div>
                  </div>
                </div>
                {/*<Button icon="pi pi-upload" label="Импорт" size="small" onClick={() => setVisible(true)}>*/}
                <Dialog
                  visible={visible}
                  modal
                  maximizable
                  header={dialogHeaderElement}
                  footer={dialogFooterContent}
                  style={{
                    width: "60rem",
                    height: "36rem",
                  }}
                  onHide={() => setVisible(false)}
                ></Dialog>
              </div>
              <div className="card">
                <Toast ref={toast} position="bottom-center" />
                <ContextMenu
                  model={menuModel}
                  ref={cm}
                  onHide={() => setSelectedRow(null)}
                />
                <DataTable
                  value={tableData}
                  size="small"
                  paginator
                  sortField="trueVerticalDepth"
                  sortOrder={1}
                  rows={10}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  selectionMode="single"
                  selection={selectedRow}
                  onSelectionChange={(e) => {
                    setSelectedRow(e.value);
                  }}
                  dataKey="trueVerticalDepth"
                  editMode="row"
                  onRowEditComplete={onRowEditComplete}
                  onContextMenu={(e) => cm.current.show(e.originalEvent)}
                  contextMenuSelection={selectedRow}
                  onContextMenuSelectionChange={(e) => setSelectedRow(e.value)}
                >
                  <Column
                    field="measuredDepth"
                    header="Кровля по стволу (м)"
                    body={measuredDepthTemplate}
                    hidden
                  />
                  <Column
                    field="trueVerticalDepth"
                    header="Кровля по вертикали (м)"
                    sortable
                    editor={(options) => numEditor(options)}
                    body={trueVerticalDepthTemplate}
                  />
                  <Column
                    field="porePressure"
                    header="Поровое давление (MПа)"
                    editor={(options) =>
                      selectedPorePressure.key === 1 ? numEditor(options) : null
                    }
                    body={porePressureTemplate}
                  />
                  <Column
                    field="reservoirGradient"
                    header="Градиент порового давления (МПа/м)"
                    editor={(options) =>
                      selectedPorePressure.key === 2 ? numEditor(options) : null
                    }
                    body={reservoirGradientTemplate}
                  />
                  <Column
                    field="reservoirDensity"
                    header={reservoirDensity}
                    editor={(options) =>
                      selectedPorePressure.key === 3 ? numEditor(options) : null
                    }
                    body={reservoirDensityTemplate}
                  />
                  <Column
                    field="fracturePressure"
                    header="Давление ГРП (МПа)"
                    editor={(options) =>
                      selectedGrp.key === 1 ? numEditor(options) : null
                    }
                    body={fracturePressureTemplate}
                  />
                  <Column
                    field="fractureGradient"
                    header="Градиент ГРП (MПа/м)"
                    editor={(options) =>
                      selectedGrp.key === 2 ? numEditor(options) : null
                    }
                    body={fractureGradientTemplate}
                  />
                  <Column
                    field="fractureDensity"
                    header={fractureDensity}
                    editor={(options) =>
                      selectedGrp.key === 3 ? numEditor(options) : null
                    }
                    body={fractureDensityTemplate}
                  />
                  <Column
                    field="mechanicalSpeed"
                    header="Механическая скорость (м/ч)"
                    editor={(options) => numEditor(options)}
                    body={mechanicalSpeedTemplate}
                  />
                  <Column
                    field="isPermeable"
                    header="Проницаемый пласт"
                    editor={(options) => toggleEditor(options)}
                    body={isPermeableTemplate}
                  />
                  <Column
                    field="cavernosity"
                    header="Коэффициент кавернознности"
                    editor={(options) => numEditor(options)}
                    body={ cavernosityTemplate }
                    hidden
                  />
                  <Column
                    rowEditor
                    header={
                      <Button
                        icon="pi pi-plus"
                        onClick={handleAddRow}
                        size="small"
                        outlined
                      />
                    }
                  />
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </SplitterPanel>
      <SplitterPanel
        className="flex align-items-center justify-content-center"
        size={35}
      >
        <Chart
          type="line"
          data={chartData}
          options={chartOptions}
          width={"98%"}
          height={"90%"}
        />
      </SplitterPanel>
    </Splitter>
  ) : (
    <ActiveCaseError pageName={"Пласты и давления"} />
  );
}

export default FormationsPressures;
