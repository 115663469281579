import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  label: "Выберете кейс для работы",
  id: null,
};

const caseSlice = createSlice({
  name: "case",
  initialState,
  reducers: {
    setCase: (state, action) => {
      state.label = action.payload.label;
      state.id = action.payload.id;
    },
    removeCase: (state) => {
      console.log("REMOVED");
      state.label = "Выберете кейс для работы";
      state.id = null;
    },
  },
});

export const { setCase, removeCase } = caseSlice.actions;

export default caseSlice.reducer;
