import React, { useState, useEffect } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Message } from "primereact/message";
import styles from "./Indicator.module.css";

function IndicatorMessage({ name, reason, recommendation, status }) {
  function getIndicatorColor() {
    switch (status) {
      case 1:
        return "var(--indicator-green)";
      case 2:
        return "var(--indicator-yellow)";
      case 3:
        return "var(--indicator-red)";
      default:
        return "var(--indicator-grey)";
    }
  }

  function getMessageSeverity() {
    switch (status) {
      case 1:
        return "success";
      case 2:
        return "warn";
      case 3:
        return "error";
      default:
        return "info";
    }
  }

  const [statusColor, setStatusColor] = useState(getIndicatorColor());
  const [severity, setSeverity] = useState(getMessageSeverity());

  useEffect(() => {
    setStatusColor(getIndicatorColor());
    setSeverity(getMessageSeverity());
  }, [status]);

  const indicator = (
    <div className={styles.header}>
      {name}
      <div
        className={
          getIndicatorColor() === "var(--indicator-red)"
            ? styles.blink_bg
            : styles.indicator_color
        }
        style={{
          backgroundColor: getIndicatorColor(),
          width: "1.75rem",
          height: "1.75rem",
        }}
      />
    </div>
  );

  const initialMessage = (
    <Accordion style={{ width: "100%" }}>
      <AccordionTab
        header={indicator}
        className={styles.custom_header}
        contentClassName="custom_content"
      >
        <div className={styles.text}>
          <h5>Причина возникновения:</h5>
          <p>{reason}</p>
        </div>
      </AccordionTab>
    </Accordion>
  );

  const message = (
    <Accordion style={{ width: "100%" }}>
      <AccordionTab
        header={indicator}
        className={styles.custom_header}
        contentClassName="custom_content"
      >
        <div className={styles.text}>
          <h5>Причина возникновения:</h5>
          <p>{reason}</p>
        </div>
        <div className={styles.text}>
          <h5>Рекомендации:</h5>
          <p>{recommendation}</p>
        </div>
      </AccordionTab>
    </Accordion>
  );

  return (
    <Message
      className={severity === "info" ? styles.info_message : styles.message}
      style={{
        border: `2px solid ${statusColor}`,
      }}
      severity={severity}
      content={status === 0 || status === 1 ? initialMessage : message}
    />
  );
}

export default IndicatorMessage;
