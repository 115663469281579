import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import { ContextMenu } from "primereact/contextmenu";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { Chart } from "primereact/chart";
import { Accordion, AccordionTab } from "primereact/accordion";
import ActiveCaseError from "../../components/ActiveCaseError/ActiveCaseError";
import moment from "moment";
import { DateTimeHelper } from "../../service/DateTimeHelper";
// -----API imports-----
import {
  useGetQuery,
  useAddMutation,
  useUpdateMutation,
  useRemoveMutation,
} from "../../store/slices/apiSlice";
import { useSelector } from "react-redux";

function DrillingFluid() {
  const caseName = useSelector((state) => state.case.label);
  const [activeCase, setActiveCase] = useState(
    true
    // caseName == "Выберете кейс для работы" ? false : true
  );
  //-----filter-input-----
  const [quantityStart, setQuantityStart] = useState();
  const [quantityEnd, setQuantityEnd] = useState();

  //-----GET Mud-Parameters-----
  const mudEP = useSelector((state) => state.endpoints.mud);
  const rheologyEP = useSelector((state) => state.endpoints.rheology);

  const { data: selectedMudData, refetch } = useGetQuery(mudEP, {
    refetchOnMountOrArgChange: true,
  });

  //-----Fluid-Datatable-----
  const [fluidName, setFluidName] = useState();
  const [fluidDensity, setFluidDensity] = useState();
  const [outFluidDensity, setOutFluidDensity] = useState();
  const [creationDate, setCreationDate] = useState();
  const [crustThickness, setCrustThickness] = useState();
  const [cusionThickness, setCusionThickness] = useState();
  const [solidPhase, setSolidPhase] = useState();
  const [lossCoefficient, setLossCoefficient] = useState();

  //-----Rheological-----
  const [rheologicalProp, setRheologicalProp] = useState([]);
  const [SNS10s, setSNS10s] = useState();
  const [SNS10m, setSNS10m] = useState();

  const viscosemeterData = [
    {
      rotationSpeed: 600,
      viscometerIndicator: 0,
    },
    {
      rotationSpeed: 300,
      viscometerIndicator: 0,
    },
    {
      rotationSpeed: 200,
      viscometerIndicator: 0,
    },
    {
      rotationSpeed: 100,
      viscometerIndicator: 0,
    },
    {
      rotationSpeed: 60,
      viscometerIndicator: 0,
    },
    {
      rotationSpeed: 30,
      viscometerIndicator: 0,
    },
    {
      rotationSpeed: 20,
      viscometerIndicator: 0,
    },
    {
      rotationSpeed: 10,
      viscometerIndicator: 0,
    },
    {
      rotationSpeed: 6,
      viscometerIndicator: 0,
    },
    {
      rotationSpeed: 3,
      viscometerIndicator: 0,
    },
    {
      rotationSpeed: 2,
      viscometerIndicator: 0,
    },
    {
      rotationSpeed: 1,
      viscometerIndicator: 0,
    },
  ];

  //-----dropdown-----
  const [selectedFilter, setSelectedFilter] = useState(null);
  const filters = [
    { name: "filter1", code: "1" },
    { name: "filter2", code: "2" },
    { name: "filter3", code: "3" },
  ];

  const [selectedFluidModel, setSelectedFluidModel] = useState(null);
  const fluidModel = [
    { name: "Автоматический расчет", code: "auto" },
    { name: "Степенная", code: "power" },
    { name: "Бингам пластик", code: "bp" },
    { name: "Гершиль-Бакли", code: "hb" },
    { name: "Ньютоновская", code: "newton" },
    { name: "Общая модель Гершиль-Бакли", code: "generalHB" },
  ];

  const [selectedFluidType, setSelectedFluidType] = useState(null);
  const fluidType = [
    { name: "Вода", code: "water" },
    { name: "РУО", code: "ryo" },
    { name: "Синтетическая жидкость", code: "syntheticFluid" },
    { name: "Солевой раствор", code: "saline" },
  ];

  const viscometerGeometry = [
    { name: "R1B1", code: "R1B1" },
    { name: "R1B2", code: "R1B2" },
    { name: "Лепестковый боб", code: "bob" },
    { name: "Вручную", code: "manual" },
  ];

  const [selectedViscometerGeometry, setSelectedViscometerGeometry] = useState(
    viscometerGeometry[0]
  );

  //-----get-datatable-data-----
  const [fluidData, setFluidData] = useState([]);
  const [selectedFluid, setSelectedFluid] = useState(null);

  const [chartDatatable, setChartDatatable] = useState(viscosemeterData);
  const [selectedChartData, setSelectedChartData] = useState();

  const { data: mudList } = useGetQuery(mudEP + "/list", {
    refetchOnMountOrArgChange: true,
  });

  const getFluidType = (type) => {
    switch (type) {
      case "Вода":
        return { name: "Вода", code: "water" };
      case "РУО":
        return { name: "РУО", code: "ryo" };
      case "Синтетическая жидкость":
        return {
          name: "Синтетическая жидкость",
          code: "syntheticFluid",
        };
      case "Солевой раствор":
        return { name: "Солевой раствор", code: "saline" };
      default:
        return {};
    }
  };

  const getFluidModel = (model) => {
    switch (model) {
      case "Автоматический расчет":
        return { name: "Автоматический расчет", code: "auto" };
      case "Степенная":
        return { name: "Степенная", code: "power" };
      case "Бингам пластик":
        return { name: "Бингам пластик", code: "bp" };
      case "Гершиль-Бакли":
        return { name: "Гершиль-Бакли", code: "hb" };
      case "Ньютоновская":
        return { name: "Ньютоновская", code: "newton" };
      case "Общая модель Гершиль-Бакли":
        return {
          name: "Общая модель Гершиль-Бакли",
          code: "generalHB",
        };
      default:
        return {};
    }
  };

  const getViscosmeter = (val) => {
    switch (val) {
      case "R1B1":
        return { name: "R1B1", code: "R1B1" };
      case "R1B2":
        return { name: "R1B2", code: "R1B2" };
      case "Лепестковый боб":
        return { name: "Лепестковый боб", code: "bob" };
      case "Вручную":
        return { name: "Вручную", code: "manual" };
      default:
        return {};
    }
  };

  const initRows = (list) => {
    console.log("rows", list);
    return list?.map((item) => {
      return {
        id: item.id,
        name: item.name,
        creationDate: item.creationDate,
        type: item.type,
        density: item.density,
        outDensity: item.outDensity,
        crustThickness: item.crustThickness,
        sludgeLayerThickness: item.sludgeLayerThickness,
        solidPhaseQuantity: item.solidPhaseQuantity,
        lossFactor: item.lossFactor,
        fluidType: item.fluidType,
        reologicalProperties: [],
      };
    });
  };

  const initRheoRows = (list) => {
    console.log("rheo rows list", list);
    let rheoList = list?.map((item) => {
      return {
        id: item.temperature,
        mudId: item.mudId,
        temperature: item.temperature,
        m: item.m,
        n: item.n,
        yp: item.yp,
        pv: item.pv,
        k: item.k,
        model: item.model,
        rSpeed600: item.rSpeed600,
        rSpeed300: item.rSpeed300,
        rSpeed200: item.rSpeed200,
        rSpeed100: item.rSpeed100,
        rSpeed60: item.rSpeed60,
        rSpeed30: item.rSpeed30,
        rSpeed20: item.rSpeed20,
        rSpeed10: item.rSpeed10,
        rSpeed6: item.rSpeed6,
        rSpeed3: item.rSpeed3,
        rSpeed2: item.rSpeed2,
        rSpeed1: item.rSpeed1,
        staticShearStress10: item.staticShearStress10,
        staticShearStress600: item.staticShearStress600,
        staticShearStress1800: item.staticShearStress1800,
        viscosityGeometry: getViscosmeter(item.viscosityGeometry),
      };
    });
    console.log(rheoList);
    return rheoList;
  };

  useEffect(() => {
    let _mudList;
    if (mudList) {
      _mudList = initRows(mudList);
    }

    console.log("LIST", _mudList);
    setFluidData(_mudList);
  }, [mudList]);

  // Получение данных с бэка
  useEffect(() => {
    console.log("refetch data:", selectedMudData);
    var tmp = {
      ...selectedMudData,
      creationDate: new Date(selectedMudData?.creationDate),
    };
    setSelectedFluid(tmp);
  }, [selectedMudData]);

  // Установка новых данных
  useEffect(() => {
    setFluidName(selectedFluid?.name);
    setCreationDate(selectedFluid?.creationDate);
    setSelectedFluidType(getFluidType(selectedFluid?.type));
    setFluidDensity(selectedFluid?.density);
    setOutFluidDensity(selectedFluid?.outDensity);
    setCrustThickness(selectedFluid?.crustThickness);
    setCusionThickness(selectedFluid?.sludgeLayerThickness);
    setSolidPhase(selectedFluid?.solidPhaseQuantity);
    setLossCoefficient(selectedFluid?.lossFactor);
    setSelectedFluidModel(getFluidModel(selectedFluid?.fluidType));

    setRheologicalProp(initRheoRows(selectedFluid?.reologicalProperties));
  }, [selectedFluid]);

  useEffect(() => {
    if (rheologicalProp?.length > 0) {
      if (selectedRheologicalProp == null)
        setSelectedRheologicalProp(rheologicalProp[0]);
    }
  }, [rheologicalProp]);

  //----context-menu-----
  const cm = useRef(null);
  const cmRheo = useRef(null);
  const toast = useRef(null);

  const fluidMenuModel = [
    {
      label: "Удалить",
      icon: "pi pi-fw pi-times",
      command: () => deleteSelectedFluid(selectedFluid),
    },
  ];

  const rheoMenuModel = [
    {
      label: "Удалить",
      icon: "pi pi-fw pi-times",
      command: () => deleteSelectedRheology(selectedRheologicalProp),
    },
  ];

  const [deleteItem] = useRemoveMutation();

  const deleteSelectedFluid = (row) => {
    deleteItem({
      endpoint: mudEP,
      id: `/${selectedFluid?.id}`,
    }).then((response) => {
      if (response) {
        toast.current.show({
          severity: "error",
          summary: "Удалено",
          detail: "Данные удалены",
          life: 3000,
        });
      }
    });

    let _fluidData = [...fluidData];
    _fluidData = _fluidData.filter((p) => p.id !== row.id);
    setFluidData(_fluidData);
  };

  const deleteSelectedRheology = (row) => {
    if (!row.isNew) {
      deleteItem({
        endpoint: rheologyEP,
        id: `/${selectedRheologicalProp?.id}`,
      }).then((response) => {
        if (response) {
          toast.current.show({
            severity: "error",
            summary: "Удалено",
            detail: "Данные удалены",
            life: 3000,
          });
        }
      });
    }

    let _rheologicalProp = [...rheologicalProp];
    _rheologicalProp = _rheologicalProp.filter((p) => p.id !== row.id);
    setRheologicalProp(_rheologicalProp);
    setCreateDisabled(false);
    setChartDatatable(viscosemeterData);
  };

  //-----Rheology-----
  const [selectedRheologicalProp, setSelectedRheologicalProp] = useState(null);

  const rheologicalPropColumns = [
    { field: "m", header: "m" },
    { field: "n", header: "n" },
    { field: "yp", header: "YP (Па)" },
    { field: "pv", header: "PV (мПа*с)" },
    { field: "k", header: "K (Па*с*n')" },
    { field: "rheologicalModel", header: "Реологическая модель" },
  ];

  const buildViscosemeterData = (rheology) => {
    return [
      {
        rotationSpeed: 600,
        viscometerIndicator: rheology.rSpeed600,
      },
      {
        rotationSpeed: 300,
        viscometerIndicator: rheology.rSpeed300,
      },
      {
        rotationSpeed: 200,
        viscometerIndicator: rheology.rSpeed200,
      },
      {
        rotationSpeed: 100,
        viscometerIndicator: rheology.rSpeed100,
      },
      {
        rotationSpeed: 60,
        viscometerIndicator: rheology.rSpeed60,
      },
      {
        rotationSpeed: 30,
        viscometerIndicator: rheology.rSpeed30,
      },
      {
        rotationSpeed: 20,
        viscometerIndicator: rheology.rSpeed20,
      },
      {
        rotationSpeed: 10,
        viscometerIndicator: rheology.rSpeed10,
      },
      {
        rotationSpeed: 6,
        viscometerIndicator: rheology.rSpeed6,
      },
      {
        rotationSpeed: 3,
        viscometerIndicator: rheology.rSpeed3,
      },
      {
        rotationSpeed: 2,
        viscometerIndicator: rheology.rSpeed2,
      },
      {
        rotationSpeed: 1,
        viscometerIndicator: rheology.rSpeed1,
      },
    ];
  };

  useEffect(() => {
    console.log("selectedRheologicalProp", selectedRheologicalProp);
    console.log("list", viscosemeterData);
    if (selectedRheologicalProp == null) return;

    setChartDatatable(buildViscosemeterData(selectedRheologicalProp));
    setSNS10s(selectedRheologicalProp.staticShearStress10);
    setSNS10m(selectedRheologicalProp.staticShearStress600);
    setSelectedViscometerGeometry(selectedRheologicalProp.viscosityGeometry);
  }, [selectedRheologicalProp]);

  //-----chart-----
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: chartDatatable.map((item) => item.rotationSpeed),
      datasets: [
        {
          label: "Показатель вискозиметра (°)",
          data: chartDatatable.map((item) => item.viscometerIndicator),
          fill: false,
          borderColor: documentStyle.getPropertyValue("--green-500"),
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
          title: {
            display: true,
            text: "Скорость вращения (об/сек)",
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
          title: {
            display: true,
            text: "Показатель вискозиметра (°)",
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [chartDatatable]);

  //-----toolbar-----
  const handleReset = () => {
    setSelectedFilter(null);
    setQuantityStart();
    setQuantityEnd();
    setSelectedFluid(null);
  };

  const startContent = (
    <React.Fragment>
      <div className="toolbar">
        <Dropdown
          placeholder="Фильтр"
          value={selectedFilter}
          onChange={(e) => setSelectedFilter(e.value)}
          options={filters}
          optionLabel="name"
          className="w-full md:w-14rem p-inputtext-sm toolbar-dropdown"
        />
        <label>Диапазон плотностей:</label>
        <div className="toolbar-input">
          <InputNumber
            placeholder="от"
            className="p-inputtext-sm"
            inputId="quantityStart"
            useGrouping={false}
            maxFractionDigits={6}
            value={quantityStart}
            onValueChange={(e) => setQuantityStart(e.value)}
          />
        </div>
        <label>-</label>
        <div className="toolbar-input">
          <InputNumber
            placeholder="до"
            className="p-inputtext-sm"
            inputId="quantityEnd"
            useGrouping={false}
            maxFractionDigits={6}
            value={quantityEnd}
            onValueChange={(e) => setQuantityEnd(e.value)}
          />
        </div>
        <Button label="Сброс" size="small" onClick={handleReset} />
      </div>
    </React.Fragment>
  );

  //-----add-datatable-row----
  const onRowEditComplete = (e) => {
    let _fluidData = [...fluidData];
    let { newData, index } = e;

    let body = {
      name: newData.name,
      creationDate: DateTimeHelper.RemoveTimeZone(newData.creationDate),
      type: newData.type,
      density: newData.density,
      outDensity: 0,
      crustThickness: 0,
      sludgeLayerThickness: 0,
      solidPhaseQuantity: 0,
      lossFactor: 0,
      fluidType: "",
    };

    if (!newData.isNew) {
      updateData({
        endpoint: mudEP,
        id: `/${newData.id}`,
        body: body,
      });
    } else {
      addData({
        endpoint: mudEP,
        id: "",
        body: body,
      }).then((response) => {
        if (response?.data) {
          newData["id"] = response.data.id;
          console.log("POST RESULT", response.data);
          _fluidData[index] = newData;
          setFluidData(_fluidData);
        }
      });
    }
  };

  function handleAddRow() {
    let row = {
      key: fluidData.length + 1,
      name: "Новый раствор",
      density: 0,
      type: "",
      foam: "",
      creationDate: new Date(),
      isNew: true,
    };

    const _fluidData = [row, ...fluidData];
    setFluidData(_fluidData);
  }

  const [createDisabled, setCreateDisabled] = useState(false);

  const onRheologicalEditComplete = (e) => {
    let _rheologicalProp = [...rheologicalProp];
    let { newData, index } = e;

    _rheologicalProp[index] = newData;
    setRheologicalProp(_rheologicalProp);
  };

  function handleAddRheoRow() {
    let row = {
      key: rheologicalProp.length + 1,
      temperature: 0,
      m: 0,
      n: 0,
      yp: 0,
      pv: 0,
      k: 0,
      rSpeed600: 0,
      rSpeed300: 0,
      rSpeed200: 0,
      rSpeed100: 0,
      rSpeed60: 0,
      rSpeed30: 0,
      rSpeed20: 0,
      rSpeed10: 0,
      rSpeed6: 0,
      rSpeed3: 0,
      rSpeed2: 0,
      rSpeed1: 0,
      rheologicalModel: "",
      staticShearStress10: 0,
      staticShearStress600: 0,
      staticShearStress1800: 0,
      viscosityGeometry: "",
      isNew: true,
    };

    const _rheologicalProp = [row, ...rheologicalProp];
    setRheologicalProp(_rheologicalProp);
    setCreateDisabled(true);
  }

  const onChartDataEditComplete = (e) => {
    /*let { rowData, newValue, index, originalEvent: event } = e;
    rowData[index] = newValue;

    console.log("rowData", rowData);
    console.log("newValue", newValue);*/

    let _chartDatatable = [...chartDatatable];
    let { newData, index } = e;

    _chartDatatable[index] = newData;
    setChartDatatable(_chartDatatable);
  };

  //-----editors-----
  const textEditor = (options) => {
    return (
      <InputText
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        className="p-inputtext-sm"
        style={{ width: "6rem" }}
      />
    );
  };

  const numEditor = (options) => {
    return (
      <InputNumber
        value={options.value}
        onChange={(e) => options.editorCallback(e.value)}
        useGrouping={false}
        maxFractionDigits={2}
        className="p-inputtext-sm"
        style={{ width: "6rem" }}
      />
    );
  };

  const dateTimeTemplate = (row) => {
    return <div>{moment(new Date(row.creationDate)).format("DD.MM.YYYY")}</div>;
  };

  const dateEditor = (options) => {
    let date = moment().toDate();

    if (options.value) {
      date = options.value;
    }
    return (
      <Calendar
        value={creationDate}
        onChange={(e) => options.editorCallback(e.value, options.index)}
        dateFormat="dd.mm.yy"
        mask="99.99.9999"
        className="p-inputtext-sm"
        style={{ width: "6rem" }}
      />
    );
  };

  const dropDownEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        onChange={(e) => options.editorCallback(e.value)}
        options={fluidType}
        placeholder="Выберете значение"
        optionLabel="name"
        optionValue="name"
        className="w-full md:w-14rem p-inputtext-sm"
        itemTemplate={(option) => {
          return <span>{option.name}</span>;
        }}
      />
    );
  };

  //-----templates-----
  const density = (
    <label>
      Плотность (г/м<sup>3</sup>)
    </label>
  );

  //-----observer-----
  const element = useRef(null);
  const [width, setWidth] = useState(0);

  const observer = useMemo(
    () =>
      new ResizeObserver((entries) => {
        setWidth(entries[0].target.getBoundingClientRect().width);
      }),
    []
  );

  const sizeRef = useCallback(
    (node) => {
      if (element !== null) {
        if (node !== null) {
          element.current = node;
          observer.observe(node);
        }
      } else {
        observer.unobserve(element.current);
        element.current = null;
      }
    },
    [observer]
  );

  //-----POST-Data-----
  const [addData] = useAddMutation();

  const handleDataSelection = (e) => {
    // TODO: добавить лоудбар
    toast.current.show({
      severity: "info",
      summary: "Обновление данных",
      detail: "Выбор активного раствора",
      life: 1500,
    });
    addData({
      endpoint: mudEP,
      id: `/${e.value?.id}`,
    }).then((response) => {
      refetch();
      if (response.data) {
        toast.current.show({
          severity: "success",
          summary: "Успешно",
          life: 1500,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Данные не загружены",
          life: 1500,
        });
      }
    });
  };

  const [updateData] = useUpdateMutation();

  const saveFluidData = () => {
    updateData({
      endpoint: mudEP,
      id: `/${selectedFluid?.id}`,
      body: {
        name: fluidName,
        creationDate: DateTimeHelper.RemoveTimeZone(creationDate),
        type: selectedFluidType?.name,
        density: fluidDensity,
        outDensity: outFluidDensity,
        crustThickness: crustThickness,
        sludgeLayerThickness: cusionThickness,
        solidPhaseQuantity: solidPhase,
        lossFactor: lossCoefficient,
        fluidType: selectedFluidModel?.name,
      },
    }).then((response) => {
      if (response.data) {
        toast.current.show({
          severity: "success",
          summary: "Успешно",
          detail: "Данные сохранены",
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "warn",
          summary: "Что-то пошло не так",
          detail: "Изменения не сохранены. Пожалуйста, попробуйте позже",
          life: 3000,
        });
      }
    });
  };

  const saveRheology = () => {
    if (
      //chartDatatable.find((item) => item.viscometerIndicator === 0) ||
      //selectedRheologicalProp?.temperature === 0 ||
      !selectedViscometerGeometry?.name
    ) {
      //if (false) {
      toast.current.show({
        severity: "error",
        summary: "Заполнены не все поля ввода",
        detail: "Пожалуйста, введите необходимую информацию",
        life: 3000,
      });
    } else {
      let body = {
        temperature: selectedRheologicalProp?.temperature,
        m: 0,
        n: 0,
        yp: 0,
        pv: 0,
        k: 0,
        model: "",
        rSpeed600: chartDatatable.find((item) => item.rotationSpeed === 600)
          .viscometerIndicator,
        rSpeed300: chartDatatable.find((item) => item.rotationSpeed === 300)
          .viscometerIndicator,
        rSpeed200: chartDatatable.find((item) => item.rotationSpeed === 200)
          .viscometerIndicator,
        rSpeed100: chartDatatable.find((item) => item.rotationSpeed === 100)
          .viscometerIndicator,
        rSpeed60: chartDatatable.find((item) => item.rotationSpeed === 60)
          .viscometerIndicator,
        rSpeed30: chartDatatable.find((item) => item.rotationSpeed === 30)
          .viscometerIndicator,
        rSpeed20: chartDatatable.find((item) => item.rotationSpeed === 20)
          .viscometerIndicator,
        rSpeed10: chartDatatable.find((item) => item.rotationSpeed === 10)
          .viscometerIndicator,
        rSpeed6: chartDatatable.find((item) => item.rotationSpeed === 6)
          .viscometerIndicator,
        rSpeed3: chartDatatable.find((item) => item.rotationSpeed === 3)
          .viscometerIndicator,
        rSpeed2: chartDatatable.find((item) => item.rotationSpeed === 2)
          .viscometerIndicator,
        rSpeed1: chartDatatable.find((item) => item.rotationSpeed === 1)
          .viscometerIndicator,
        staticShearStress10: SNS10s ?? 0,
        staticShearStress600: SNS10m ?? 0,
        staticShearStress1800: 0,
        viscosityGeometry: selectedViscometerGeometry.name,
      };

      console.log("body", body);

      if (selectedRheologicalProp.isNew) {
        addData({
          endpoint: rheologyEP,
          id: "",
          body: body,
        }).then((response) => {
          if (response?.data) {
            /* newData = response.data;
            console.log("POST RESULT", response.data);
            _rheologicalProp[index] = newData;
            setRheologicalProp(_rheologicalProp);*/
            setCreateDisabled(false);

            toast.current.show({
              severity: "success",
              summary: "Успешно",
              detail: "Данные сохранены",
              life: 3000,
            });
          }
        });
      } else {
        updateData({
          endpoint: rheologyEP,
          id: `/${body.temperature}`,
          body: body,
        }).then((response) => {
          if (response?.data) {
            /*newData["id"] = response.data.id;
            console.log("POST RESULT", response.data);
            _rheologicalProp[index] = newData;
            setRheologicalProp(_rheologicalProp);*/
            setCreateDisabled(false);

            toast.current.show({
              severity: "success",
              summary: "Успешно",
              detail: "Данные сохранены",
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "error",
              summary: "Ошибка!",
              detail: `${response.error?.code} ${response.error?.data}`,
              life: 3000,
            });
          }
        });
      }
    }
  };

  return activeCase ? (
    <div
      className="main-home-container"
      id="drillingFluid"
      style={{ paddingBottom: "6rem", overflowY: "scroll" }}
      ref={sizeRef}
    >
      <h4>Буровой раствор</h4>
      <div className="card" style={{ paddingBottom: "1rem" }}>
        <Toast ref={toast} position="bottom-center" />
        {/*<Toolbar start={startContent}  />*/}
        <ContextMenu
          model={fluidMenuModel}
          ref={cm}
          onHide={() => setSelectedFluid(null)}
        />
        <DataTable
          value={fluidData}
          size="small"
          removableSort
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          selectionMode="single"
          metaKeySelection={true}
          selection={selectedFluid}
          onSelectionChange={handleDataSelection}
          dataKey="id"
          editMode="row"
          onRowEditComplete={onRowEditComplete}
          onContextMenu={(e) => cm.current.show(e.originalEvent)}
          contextMenuSelection={selectedFluid}
          onContextMenuSelectionChange={(e) => setSelectedFluid(e.value)}
        >
          <Column
            field="name"
            header="Название"
            editor={(options) => textEditor(options)}
            sortable
          />
          <Column
            field="density"
            header={density}
            editor={(options) => numEditor(options)}
            sortable
          />
          <Column
            field="type"
            header="Тип"
            editor={(options) => dropDownEditor(options)}
            sortable
          />
          {/*<Column
            field="foam"
            header="Может быть вспенен?"
            editor={(options) => textEditor(options)}
          />*/}
          <Column
            field="creationDate"
            header="Дата создания"
            body={dateTimeTemplate}
            editor={(options) => dateEditor(options)}
            sortable
          />
          <Column
            rowEditor
            header={
              <Button
                icon="pi pi-plus"
                onClick={handleAddRow}
                size="small"
                outlined
              />
            }
          />
        </DataTable>
      </div>

      <Accordion multiple activeIndex={[0, 1]} style={{ fontSize: ".9rem" }}>
        <AccordionTab header="Характеристика выбранной жидкости">
          <div className="creation-block">
            <div className="tabpanel-input-wrapper">
              <label className="name-label">Название:</label>
              <div className="toolbar-dropdown">
                <InputText
                  className="p-inputtext-sm"
                  inputId="fluidName"
                  value={fluidName}
                  onChange={(e) => setFluidName(e.target.value)}
                />
              </div>
            </div>
            <div className="tabpanel-input-wrapper">
              <label className="name-label">Плотность раствора:</label>
              <InputNumber
                className="p-inputtext-sm toolbar-dropdown"
                inputId="fluidDensity"
                useGrouping={false}
                maxFractionDigits={6}
                value={fluidDensity}
                onValueChange={(e) => setFluidDensity(e.value)}
              />
              <label>
                г/см<sup>3</sup>
              </label>
            </div>
            <div className="tabpanel-input-wrapper">
              <label className="name-label">
                Плотность выходящего раствора:
              </label>
              <InputNumber
                className="p-inputtext-sm toolbar-dropdown"
                inputId="exitFluidDensity"
                useGrouping={false}
                maxFractionDigits={6}
                value={outFluidDensity}
                onValueChange={(e) => setOutFluidDensity(e.value)}
              />
              <label>
                г/см<sup>3</sup>
              </label>
            </div>
            <div className="tabpanel-input-wrapper">
              <label className="name-label">Дата создания:</label>
              <Calendar
                value={creationDate}
                onChange={(e) => setCreationDate(e.value)}
                dateFormat="dd.mm.yy"
                className="p-inputtext-sm toolbar-dropdown"
              />
            </div>
            <div className="tabpanel-input-wrapper">
              <label className="name-label">Тип бурового раствора:</label>
              <Dropdown
                value={selectedFluidType}
                onChange={(e) => setSelectedFluidType(e.value)}
                options={fluidType}
                optionLabel="name"
                className="w-full md:w-14rem p-inputtext-sm toolbar-dropdown"
              />
            </div>
            <div className="tabpanel-input-wrapper">
              <label className="name-label">Толщина корки:</label>
              <InputNumber
                className="p-inputtext-sm toolbar-dropdown"
                inputId="crustThickness"
                useGrouping={false}
                maxFractionDigits={6}
                value={crustThickness}
                onValueChange={(e) => setCrustThickness(e.value)}
              />
              <label>мм</label>
            </div>
            <div className="tabpanel-input-wrapper" hidden>
              <label className="name-label">Толщина шламовой подушки:</label>
              <InputNumber
                hidden
                className="p-inputtext-sm toolbar-dropdown"
                inputId="cusionThickness"
                useGrouping={false}
                maxFractionDigits={6}
                value={cusionThickness}
                onValueChange={(e) => setCusionThickness(e.value)}
              />
            </div>
            <div className="tabpanel-input-wrapper">
              <label className="name-label">Модель жидкости:</label>
              <Dropdown
                value={selectedFluidModel}
                onChange={(e) => setSelectedFluidModel(e.value)}
                options={fluidModel}
                optionLabel="name"
                className="w-full md:w-14rem p-inputtext-sm toolbar-dropdown"
              />
            </div>
            <div className="tabpanel-input-wrapper">
              <label className="name-label">Содержание твердой фазы:</label>
              <InputNumber
                className="p-inputtext-sm toolbar-dropdown"
                inputId="solidPhase"
                useGrouping={false}
                maxFractionDigits={6}
                value={solidPhase}
                onValueChange={(e) => setSolidPhase(e.value)}
              />
              <label>%</label>
            </div>
            <div className="tabpanel-input-wrapper">
              <label className="name-label">Коэффициент потерь раствора:</label>
              <InputNumber
                className="p-inputtext-sm toolbar-dropdown"
                inputId="lossCoefficient"
                useGrouping={false}
                maxFractionDigits={6}
                value={lossCoefficient}
                onValueChange={(e) => setLossCoefficient(e.value)}
              />
            </div>
            <div style={{ justifyContent: "end", display: "flex" }}>
              <Button
                label="Сохранить"
                className="custom-btn"
                size="small"
                onClick={saveFluidData}
              />
            </div>
          </div>
        </AccordionTab>

        <AccordionTab
          header="Реологические свойства"
          style={{ display: "flex", width: "100%" }}
        >
          <div className="creation-block">
            <div
              className="row-block"
              style={{
                display: "flex",
                flexDirection: width < 1480 ? "column" : "row",
              }}
            >
              <div className="collumn-block">
                <div className="card">
                  <ContextMenu
                    model={rheoMenuModel}
                    ref={cmRheo}
                    onHide={() => setSelectedRheologicalProp(null)}
                  />
                  <DataTable
                    value={rheologicalProp}
                    size="small"
                    selectionMode="single"
                    metaKeySelection="true"
                    selection={selectedRheologicalProp}
                    onSelectionChange={(e) => {
                      refetch();
                      setSelectedRheologicalProp(e.value);
                    }}
                    dataKey="id"
                    editMode="row"
                    onRowEditComplete={onRheologicalEditComplete}
                    onContextMenu={(e) => cmRheo.current.show(e.originalEvent)}
                    contextMenuSelection={selectedRheologicalProp}
                    onContextMenuSelectionChange={(e) =>
                      setSelectedRheologicalProp(e.value)
                    }
                  >
                    <Column
                      field="temperature"
                      header="Температура (°C)"
                      editor={(options) => numEditor(options)}
                    />
                    {rheologicalPropColumns.map((col) => (
                      <Column
                        key={col.field}
                        field={col.field}
                        header={col.header}
                        body={(row) => row[col.field]?.toFixed(3)}
                      />
                    ))}
                    <Column
                      rowEditor
                      header={
                        <Button
                          icon="pi pi-plus"
                          onClick={handleAddRheoRow}
                          size="small"
                          outlined
                          disabled={createDisabled}
                        />
                      }
                    />
                  </DataTable>
                </div>
              </div>
              <div className="row-block-sm">
                <div className="collumn-block">
                  <div className="card" style={{ width: "100%" }}>
                    <Chart
                      type="line"
                      data={chartData}
                      options={chartOptions}
                    />
                  </div>
                  <label className="highlight-label">
                    Показания вискозиметра при
                  </label>
                  <div className="tabpanel-input-wrapper">
                    <label style={{ minWidth: "185px" }}>
                      Геометрия вискозиметра:
                    </label>
                    <Dropdown
                      value={selectedViscometerGeometry}
                      onChange={(e) => setSelectedViscometerGeometry(e.value)}
                      options={viscometerGeometry}
                      optionLabel="name"
                      className="w-full md:w-14rem p-inputtext-sm"
                      style={{ minWidth: "12rem" }}
                    />
                  </div>
                  <div className="tabpanel-input-wrapper">
                    <label>СНС 10сек:</label>
                    <InputNumber
                      className="p-inputtext-sm toolbar-input"
                      inputId="sns10s"
                      useGrouping={false}
                      maxFractionDigits={6}
                      value={SNS10s}
                      onValueChange={(e) => setSNS10s(e.value)}
                    />
                  </div>
                  <div className="tabpanel-input-wrapper">
                    <label>СНС 10мин:</label>
                    <InputNumber
                      className="p-inputtext-sm toolbar-input"
                      inputId="sns10m"
                      useGrouping={false}
                      maxFractionDigits={6}
                      value={SNS10m}
                      onValueChange={(e) => setSNS10m(e.value)}
                    />
                  </div>
                </div>
                <div className="collumn-block">
                  <div className="card">
                    <DataTable
                      value={chartDatatable}
                      size="small"
                      removableSort
                      selectionMode="single"
                      selection={selectedChartData}
                      onSelectionChange={(e) => setSelectedChartData(e.value)}
                      dataKey="rotationSpeed"
                      editMode="row"
                      onRowEditComplete={onChartDataEditComplete}
                    >
                      <Column
                        field="rotationSpeed"
                        header="Скорость вращения (об/мин)"
                        sortable
                      />
                      <Column
                        field="viscometerIndicator"
                        header="Показатель вискозиметра (°)"
                        editor={(options) => numEditor(options)}
                        sortable
                      />
                      <Column rowEditor />
                    </DataTable>
                  </div>
                  <div>
                    <Button
                      label="Автоматический выбор реологической модели"
                      size="small"
                      outlined
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "end",
                paddingTop: "2rem",
              }}
            >
              <Button
                label="Сохранить"
                size="small"
                className="custom-btn"
                onClick={saveRheology}
              />
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  ) : (
    <ActiveCaseError pageName={"Буровой раствор"} />
  );
}

export default DrillingFluid;
