import React, { useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useSelector } from "react-redux";
import { useGetQuery, useAddMutation } from "../../store/slices/apiSlice";
import offshorewell from "../../images/offshorewell.png";
import onshorewell from "../../images/onshorewell.png";

function NewCase() {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const customersEP = useSelector((state) => state.endpoints.customers);
  const { data: fetchedCustomers } = useGetQuery(customersEP, {
    refetchOnMountOrArgChange: true,
  });
  const customers = fetchedCustomers?.items?.map((customer) => {
    return {
      id: customer.id,
      name: customer.name,
      children: customer.fields,
    };
  });

  const [selectedField, setSelectedField] = useState(null);
  const fieldsEP = useSelector((state) => state.endpoints.fields);
  const { data: fetchedFields } = useGetQuery(fieldsEP, {
    refetchOnMountOrArgChange: true,
  });
  const fieldsItems = fetchedFields?.items?.map((field) => {
    return {
      id: field.id,
      parent: field.customerId,
      name: field.name,
      children: field.wells,
    };
  });
  const fields = fieldsItems?.filter(function (item) {
    return item?.parent === selectedCustomer?.id;
  });

  const [selectedCluster, setSelectedCluster] = useState(null);
  const wellClustersEP = useSelector((state) => state.endpoints.wellClusters);
  const { data: fetchedWellClusters } = useGetQuery(wellClustersEP, {
    refetchOnMountOrArgChange: true,
  });
  const wellClustersItems = fetchedWellClusters?.items?.map((wellCluster) => {
    return {
      id: wellCluster.id,
      parent: wellCluster.fieldId,
      name: wellCluster.name,
      children: wellCluster.wells,
    };
  });
  const clusters = wellClustersItems?.filter(function (item) {
    return item?.parent === selectedField?.id;
  });

  const [selectedWell, setSelectedWell] = useState(null);
  const wellsEP = useSelector((state) => state.endpoints.wells);
  const { data: fetchedWells } = useGetQuery(wellsEP, {
    refetchOnMountOrArgChange: true,
  });
  const wellsItems = fetchedWells?.items?.map((well) => {
    return {
      id: well.id,
      parent: well.clusterId,
      name: well.name,
      children: well.sections,
    };
  });
  const wells = wellsItems?.filter(function (item) {
    return item?.parent === selectedCluster?.id;
  });

  const [selectedSection, setSelectedSection] = useState(null);
  const wellSectionsEP = useSelector((state) => state.endpoints.wellSections);
  const { data: fetchedWellSections } = useGetQuery(wellSectionsEP, {
    refetchOnMountOrArgChange: true,
  });
  let wellSectionsItems = fetchedWellSections?.items?.map((wellSection) => {
    return {
      id: wellSection.id,
      parent: wellSection.wellId,
      name: wellSection.name,
    };
  });
  const sections = wellSectionsItems?.filter(function (item) {
    return item?.parent === selectedWell?.id;
  });

  const [caseName, setCaseName] = useState();

  const [selectedUnit, setSelectedUnit] = useState(null);
  const units = [{ name: "SI" }, { name: "ISO" }, { name: "Metric" }];

  const toast = useRef(null);
  const onUpload = () => {
    toast.current.show({
      severity: "info",
      summary: "Успешно",
      detail: "Файл загружен",
    });
  };

  const [wellLocation, setWellLocation] = useState("Наземное");
  const [startPointHeight, setStartPointHeight] = useState();
  const [wellDepth, setWellDepth] = useState();
  const [waterDepth, setWaterDepth] = useState();

  const [createCase] = useAddMutation();
  const caseEP = useSelector((state) => state.endpoints.cases);

  const postNewCase = () => {
    createCase({
      endpoint: caseEP,
      id: "",
      body: {
        name: caseName,
        wellSectionId: selectedSection?.id,
      },
    })
      .then((response) => {
        if (response) {
          toast.current.show({
            severity: "success",
            summary: "Успешно",
            detail: "Данные сохранены",
            life: 3000,
          });
        }
      })
      .catch((error) => {
        toast.current.show({
          severity: "warn",
          summary: "Что-то пошло не так",
          detail: "Пожалуйста, попробуйте позже",
          life: 3000,
        });
      });
  };

  return (
    <div
      className="main-home-container"
      id="newCase"
      style={{ overflowY: "scroll" }}
    >
      <h4>Новый кейс</h4>
      <Toast ref={toast} position="bottom-center" />
      <div className="tabpanel-content">
        <div className="tabpanel-input-wrapper">
          <p className="tabpanel-input-wrapper-p">Заказчик:</p>
          <Dropdown
            filter
            value={selectedCustomer}
            onChange={(e) => setSelectedCustomer(e.value)}
            options={customers}
            optionLabel="name"
            placeholder="Выберете значение"
            className="w-full md:w-14rem p-inputtext-sm dropdown-custom-long"
          />
        </div>
        <div
          className="tabpanel-input-wrapper"
          style={{ display: selectedCustomer ? "flex" : "none" }}
        >
          <p className="tabpanel-input-wrapper-p">Месторождение:</p>
          <Dropdown
            filter
            value={selectedField}
            onChange={(e) => setSelectedField(e.value)}
            options={fields}
            optionLabel="name"
            placeholder="Выберете значение"
            className="w-full md:w-14rem p-inputtext-sm dropdown-custom-long"
          />
        </div>
        <div
          className="tabpanel-input-wrapper"
          style={{ display: selectedField ? "flex" : "none" }}
        >
          <p className="tabpanel-input-wrapper-p">Куст:</p>
          <Dropdown
            filter
            value={selectedCluster}
            onChange={(e) => setSelectedCluster(e.value)}
            options={clusters}
            optionLabel="name"
            placeholder="Выберете значение"
            className="w-full md:w-14rem p-inputtext-sm dropdown-custom-long"
          />
        </div>
        <div
          className="tabpanel-input-wrapper"
          style={{ display: selectedCluster ? "flex" : "none" }}
        >
          <p className="tabpanel-input-wrapper-p">Скважина:</p>
          <Dropdown
            filter
            value={selectedWell}
            onChange={(e) => setSelectedWell(e.value)}
            options={wells}
            optionLabel="name"
            placeholder="Выберете значение"
            className="w-full md:w-14rem p-inputtext-sm dropdown-custom-long"
          />
          <FileUpload
            mode="basic"
            name="demo[]"
            url="/api/upload"
            accept="/*"
            onUpload={onUpload}
            chooseOptions={{
              label: "Выбрать файл",
              icon: "pi pi-fw pi-plus",
              style: {
                fontSize: "0.875rem",
                background: "var(--primary-color)",
                border: "1px solid var(--primary-color)",
              },
            }}
          />
        </div>
        <div
          className="tabpanel-input-wrapper"
          style={{ display: selectedWell ? "flex" : "none" }}
        >
          <p className="tabpanel-input-wrapper-p">Секция:</p>
          <Dropdown
            filter
            value={selectedSection}
            onChange={(e) => setSelectedSection(e.value)}
            options={sections}
            optionLabel="name"
            placeholder="Выберете значение"
            className="w-full md:w-14rem p-inputtext-sm dropdown-custom-long"
          />
        </div>
        <div
          className="tabpanel-input-wrapper"
          style={{ display: selectedSection ? "flex" : "none" }}
        >
          <p className="tabpanel-input-wrapper-p">Кейс:</p>
          <InputText
            value={caseName}
            onChange={(e) => setCaseName(e.target.value)}
            className="p-inputtext-sm dropdown-custom-long"
          />
        </div>
        {/*<div
          className="tabpanel-input-wrapper"
          style={{ display: caseName ? "flex" : "none" }}
        >
          <p className="tabpanel-input-wrapper-p">Единицы измерения:</p>
          <Dropdown
            value={selectedUnit}
            onChange={(e) => setSelectedUnit(e.value)}
            options={units}
            optionLabel="name"
            placeholder="Выберете значение"
            className="w-full md:w-14rem p-inputtext-sm dropdown-custom-long"
          />
          </div>*/}
      </div>

      <label
        style={{
          display: caseName ? "flex" : "none",
          //display: selectedUnit ? "block" : "none",
          paddingTop: "4rem",
          fontWeight: "600",
          fontSize: "0.9rem",
        }}
      >
        Информация по началу отсчета
      </label>
      <div
        style={{
          display: caseName ? "flex" : "none",
          //display: selectedUnit ? "flex" : "none",
          padding: "1rem 0 4rem 0",
          gap: "4rem",
        }}
      >
        <div>
          <img
            src={wellLocation === "Наземное" ? onshorewell : offshorewell}
            style={{ width: "30vw" }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div className="tabpanel-content">
            <div className="tabpanel-input-wrapper">
              <label style={{ width: "11rem" }}>Расположение</label>
              <div style={{ display: "flex", gap: "10px" }}>
                <RadioButton
                  inputId="onShore"
                  name="onShore"
                  value="Наземное"
                  onChange={(e) => setWellLocation(e.value)}
                  checked={wellLocation === "Наземное"}
                />
                <label htmlFor="onShore" className="ml-2">
                  Наземное
                </label>
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <RadioButton
                  inputId="offShore"
                  name="offShore"
                  value="Морское"
                  onChange={(e) => setWellLocation(e.value)}
                  checked={wellLocation === "Морское"}
                />
                <label htmlFor="offShore" className="ml-2">
                  Морское
                </label>
              </div>
            </div>
            <div className="tabpanel-input-wrapper">
              <label style={{ width: "11rem" }}>Высота точки отсчета:</label>
              <InputNumber
                className="p-inputtext-sm"
                inputId="startPointHeight"
                useGrouping={false}
                maxFractionDigits={6}
                value={startPointHeight}
                onValueChange={(e) => setStartPointHeight(e.value)}
              />
              <label>м</label>
            </div>

            <div
              className="tabpanel-input-wrapper"
              style={{ display: wellLocation === "Морское" ? "flex" : "none" }}
            >
              <label style={{ width: "11rem" }}>Глубина воды:</label>
              <InputNumber
                className="p-inputtext-sm"
                inputId="waterDepth"
                useGrouping={false}
                maxFractionDigits={6}
                value={waterDepth}
                onValueChange={(e) => setWaterDepth(e.value)}
              />
              <label>м</label>
            </div>

            <div className="tabpanel-input-wrapper">
              <label style={{ width: "11rem" }}>Глубина скважины:</label>
              <InputNumber
                className="p-inputtext-sm"
                inputId="wellHeight"
                useGrouping={false}
                maxFractionDigits={6}
                value={wellDepth}
                onValueChange={(e) => setWellDepth(e.value)}
              />
              <label>м</label>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              size="small"
              className="custom-btn"
              label="Создать"
              onClick={postNewCase}
            />
          </div>
        </div>
      </div>
      {/* */}
    </div>
  );
}

export default NewCase;
