import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../store/slices/navigationSlice";

function ActiveCaseError({ pageName }) {
  const dispatch = useDispatch();
  const isClosed = useSelector((state) => state.navigation.isClosed);

  const navigateTo = () => {
    if (isClosed)
      dispatch(
        setActivePage({
          activePage: "allCases",
          activeMenuIndex: -1,
          isClosed: isClosed,
        })
      );
    else
      dispatch(
        setActivePage({
          activePage: "allCases",
          activeMenuIndex: 1,
          isClosed: isClosed,
        })
      );
  };

  return (
    <div className="main-home-container">
      <h4>{pageName}</h4>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p>Активный кейс не выбран</p>
        <Link
          to="/allCases"
          onClick={navigateTo}
          style={{ fontWeight: "600", color: "var(--primary-color)" }}
        >
          Выбрать кейс для работы
        </Link>
      </div>
    </div>
  );
}

export default ActiveCaseError;
