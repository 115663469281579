import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { PrimeReactProvider } from "primereact/api";
import { Provider } from "react-redux";
import store from "./store/store";
import RigDataListener from "./store/rig-data-ws/rigDataListener";

const MessageRecieveHandler = (data) => {
  console.log(data);
};
const root = ReactDOM.createRoot(document.getElementById("root"));
RigDataListener.addEventListener("MessageRecieved", MessageRecieveHandler);

root.render(
  <Provider store={store}>
    {/*<React.StrictMode>*/}
    <PrimeReactProvider>
      <App />
    </PrimeReactProvider>
    {/*</React.StrictMode>*/}
  </Provider>
);
