export let indicators = [
  {
    key: "hydrostatic_pressure",
    name: "Риск ГНВП",
    value: "",
    status: 0,
    reason: "Низкое гидростатическое давление.",
    recommendation: "Остановите бурение! Проверьте скважину на приток!",
  },
  {
    key: "work_in_slide",
    name: "Работа в слайде",
    value: "",
    status: 0,
    reason: "Работа в слайде более 30 минут.",
    recommendation:
      "Перейдите на работу в роторном режиме или повращайте БК и промойте скважину от шлама. Большой риск возникновения прихвата.",
  },
  {
    key: "torques",
    name: "Крутящий момент",
    value: "",
    status: 0,
    reason: "Скопление шлама или «подлипание» инструмента.",
    recommendation:
      "Инструмент «подлипает». Промойте скважину и обработайте раствор смазывающими добавками.",
  },
  {
    key: "is_permeable",
    name: "Проницаемый пласт",
    value: "",
    status: 0,
    reason: "КНБК проходит интервал проницаемого пласта.",
    recommendation:
      "Сигнал носит информативный характер, сохраняйте бдительность по остальным сигналам.",
  },
  {
    key: "cutting_beds",
    name: "Шламовая подушка",
    value: "",
    status: 0,
    reason:
      "Недостаточная производительность насоса. Бурение без вращения, или недостаточная частота вращения ротора/СВП для выноса шлама на поверхность.",
    recommendation:
      "Большое количество шлама в кольцевом пространстве. Промойте скважину с вращением инструмента 120 об/мин, или проведите «очищающий» рейс.",
  },
  {
    key: "repression",
    name: "Репрессия",
    value: "",
    status: 0,
    reason: "Высокое гидростатическое или гидродинамическое давление.",
    recommendation:
      "Большая репрессия. Проверьте плотность раствора. Проверьте количество шлама в КП. Снизьте ЭЦП.",
  },
  {
    key: "mechanical_speed",
    name: "Мех. скорость",
    value: "",
    status: 0,
    reason: "Нахождение в проницаемом пласте. Мехскорость выше плановой.",
    recommendation:
      "Ограничьте нагрузку на долото и скорость проходки до плановой.",
  },
  {
    key: "slope_angle",
    name: "Угол наклона",
    value: "",
    status: 0,
    reason: "Угол наклона способствует образованию прихватов.",
    recommendation:
      "Сигнал носит информативный характер, сохраняйте бдительность по остальным сигналам.",
  },
  {
    key: "filter_crust_thickness",
    name: "Толщина фильтр. корки",
    value: "",
    status: 0,
    reason: "Плохое качество бурового раствора.",
    recommendation:
      "Фильтрационная корка выше нормы. Необходимо обработать раствор.",
  },
  {
    key: "fixed_drill_pipe",
    name: "Неподвижное состояние БК",
    value: "",
    status: 0,
    reason: "БК находится без движения.",
    recommendation:
      "Необходимо произвести расхаживание или вращение бурильной колонны.",
  },
  {
    key: "fracture_pressure",
    name: "Риск ГРП",
    value: "",
    status: 0,
    reason: "Высокое гидродинамическое давление (ЭЦП).",
    recommendation:
      "Возможно поглощение раствора! Проверьте плотность раствора и соответствие производительности насоса расчётной.",
  },
];
